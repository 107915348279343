import React from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Grow,
    TextField,
    Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { IProject } from '../../models/project';
import { cancelProject, createProject, updateProject } from '../../service/project.service';
import { IUser } from '../../models/user';
import SelectAddress from '../data/SelectAddress';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormErrors } from '../../actions/formError.actions';
import { DoDisturb } from '@mui/icons-material';
import { setPrompt } from '../../actions/prompt.actions';
import SelectContact from '../data/SelectContact';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import { useHistory } from 'react-router-dom';
import SelectStatus from '../data/SelectStatus';

interface AddProjectDialogProps {
    onFinish: () => void,
    onClose?: () => void,
    initialUser?: IUser,
    initialData?: IProject,
    intern?: boolean,
    children?: any,
    userName?: string,
    initialOpen?: boolean,
    readOnly?: boolean,
}

const defaultData: IProject = {
    name: '',
    status: 'Offen',
    start_date: null,
    end_date: null,
    delivery_date: null,
    pickup_date: null,
    delivery_instructions: '',
    address_id: undefined,
};

function ProjectDialog({ onFinish, onClose, initialUser, initialData, intern, children, userName, initialOpen, readOnly }: AddProjectDialogProps) {
    const dispatch = useDispatch();
    const history = useHistory();
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);
    const [open, setOpen] = React.useState<boolean>(initialOpen ?? false);
    const [project, setProject] = React.useState<IProject>(initialData ?? defaultData);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string>();

    React.useEffect(() => {
        if (!open || !initialData?.user) {
            return;
        }
    }, [open]);

    const user: IUser = initialUser ?? initialData!.user!;

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        if (onClose) {
            onClose();
        }
    };

    const handleSubmit = async (): Promise<void> => {
        dispatch(clearFormErrors());

        const updated: any = {
            ...project,
            start_date: project.start_date?.format(),
            end_date: project.end_date?.format(),
            delivery_date: project.delivery_date?.format(),
            pickup_date: project.pickup_date ? project.pickup_date.format() : null,
            address_id: project.address_id ?? null,
            contact_id: project.contact_id ?? null,
        };

        setProcessing(true);
        try {
            if (!initialData) {
                await createProject(updated);
            } else {
                await updateProject(updated);
            }
        } catch (ex: any) {
            setProcessing(false);

            if (ex.response?.status === 403) {
                setError('Das Projekt kann nicht geändert werden, weil es bereits angefragt ist.');
            }

            return;
        }

        setProcessing(false);
        handleClose();
        onFinish();
    };

    const handleCancel = async (confirm: boolean): Promise<void> => {
        if (!confirm) {
            dispatch(setPrompt('Wirklich stornieren?', '', () => handleCancel(true)));
            return
        }

        setProcessing(true);

        await cancelProject(project.id!);

        setProcessing(false);
        handleClose();
        onFinish();
    }

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={intern ? 'xl' : 'sm'}>
                <DialogTitle>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            {initialData ? 'Projekt bearbeiten' : 'Neues Projekt erstellen'}
                        </Grid>
                        {initialData && (
                            <Grid item>
                                <Button onClick={() => history.push(`/projekte/${project.id}/warenkorb`)} variant="outlined" startIcon={<ShoppingBasketIcon />}>
                                    Warenkorb
                                </Button>
                            </Grid>
                        )}
                    </Grid>
                </DialogTitle>
                <DialogContent>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={intern ? 6 : 12}>
                            <Grid container spacing={2} style={{ marginTop: 5 }}>
                                <Grow in={Boolean(error)} unmountOnExit>
                                    <Grid item xs={12} style={{ marginBottom: 10 }}>
                                        <Alert severity="warning">
                                            {error}
                                        </Alert>
                                    </Grid>
                                </Grow>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        label="Projektname"
                                        size="small"
                                        value={project.name}
                                        onChange={event => setProject({ ...project, name: event.target.value })}
                                        error={Boolean(formErrors.name)}
                                        helperText={formErrors.name}
                                        disabled={readOnly}
                                    />
                                </Grid>

                                <Grid item xs={12} md={6}>
                                    <DesktopDatePicker
                                        label="VA-Startdatum"
                                        inputFormat="DD.MM.YYYY"
                                        value={project.start_date}
                                        onChange={(value: any) => setProject({ ...project, start_date: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.start_date)}
                                                helperText={formErrors.start_date}
                                                {...params}
                                            />
                                        )}
                                        maxDate={project.end_date}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDatePicker
                                        label="VA-Enddatum"
                                        inputFormat="DD.MM.YYYY"
                                        value={project.end_date}
                                        onChange={(value: any) => setProject({ ...project, end_date: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.end_date)}
                                                helperText={formErrors.end_date}
                                                {...params}
                                            />
                                        )}
                                        minDate={project.start_date}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDatePicker
                                        label="Lieferdatum"
                                        inputFormat="DD.MM.YYYY"
                                        value={project.delivery_date}
                                        onChange={(value: any) => setProject({ ...project, delivery_date: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                required
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.delivery_date)}
                                                helperText={formErrors.delivery_date}
                                                {...params}
                                            />
                                        )}
                                        maxDate={project.pickup_date}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <DesktopDatePicker
                                        label="Abholdatum"
                                        inputFormat="DD.MM.YYYY"
                                        value={project.pickup_date}
                                        onChange={(value: any) => setProject({ ...project, pickup_date: value })}
                                        renderInput={(params: any) => (
                                            <TextField
                                                fullWidth
                                                size="small"
                                                error={Boolean(formErrors.pickup_date)}
                                                helperText={formErrors.pickup_date}
                                                {...params}
                                            />
                                        )}
                                        disabled={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        disabled={intern || readOnly}
                                        fullWidth
                                        multiline
                                        rows={4}
                                        maxRows={4}
                                        label={intern ? 'Lieferanweisung / Kommentar von Kunde' : 'Lieferanweisung / Kommentar'}
                                        value={project.delivery_instructions}
                                        onChange={event => setProject({ ...project, delivery_instructions: event.target.value })}
                                    />
                                </Grid>

                                <Grid item xs={12} />

                                <Grid item xs={12}>
                                    <Typography variant="h6">Besteller</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    {project.address?.id ? (
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            value={`${userName ?? user.name} (${project.address?.address_street} ${project.address?.address_house_number}, ${project.address?.address_zip} ${project.address?.address_location})`}
                                            disabled
                                        />
                                    ) : (
                                        <TextField
                                            required
                                            fullWidth
                                            size="small"
                                            value={`${userName ?? user.name} (${user.address_street} ${user.address_house_number}, ${user.address_zip} ${user.address_location})`}
                                            disabled
                                        />
                                    )}
                                </Grid>
                                {intern && (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Email"
                                            size="small"
                                            value={user.email}
                                            disabled
                                        />
                                    </Grid>
                                )}
                                {intern && (
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            required
                                            fullWidth
                                            label="Telefon"
                                            size="small"
                                            value={user.phone_number}
                                            disabled
                                        />
                                    </Grid>
                                )}

                                <Grid item xs={12} />

                                <Grid item xs={12}>
                                    <Accordion variant="outlined" defaultExpanded>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Lieferadresse & Ansprechpartner</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SelectAddress
                                                        onChange={id => setProject({ ...project, address_id: id })}
                                                        value={project.address_id}
                                                    />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <SelectContact
                                                        onChange={id => setProject({ ...project, contact_id: id })}
                                                        value={project.contact_id}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Grid>
                            </Grid>
                        </Grid>
                        {intern && (
                            <Grid item xs={12} md={intern ? 6 : 12}>
                                <Grid container spacing={2} style={{ marginTop: 5 }}>
                                    <Grid item xs={12} md={4}>
                                        <TextField
                                            fullWidth
                                            label="Projekt Id"
                                            size="small"
                                            disabled
                                            value={project.id}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <TextField
                                            fullWidth
                                            label="Jobnummer"
                                            size="small"
                                            value={project.job_number}
                                            onChange={event => setProject({ ...project, job_number: event.target.value })}
                                            error={Boolean(formErrors.job_number)}
                                            helperText={formErrors.job_number}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: 7 }}>
                                        <Typography variant="h6">
                                            Status
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <SelectStatus
                                            value={project.status}
                                            onChange={status => setProject({ ...project, status })}
                                            error={formErrors.status}
                                            readOnly={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            multiline
                                            rows={4}
                                            maxRows={4}
                                            label="Hinweise für Logistik & Durchführung"
                                            value={project.intern_notes}
                                            onChange={event => setProject({ ...project, intern_notes: event.target.value })}
                                            disabled={readOnly}
                                        />
                                    </Grid>
                                    <Grid item xs={12} />
                                    <Grid item xs={12}>
                                        <Typography variant="h6">
                                            Sonstiges
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            label="Erstellt am"
                                            size="small"
                                            value={project.created_at?.format('DD.MM.YYYY HH:mm:ss')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            label="Geändert am"
                                            size="small"
                                            value={project.updated_at?.format('DD.MM.YYYY HH:mm:ss')}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            disabled
                                            label="Angefragt am"
                                            size="small"
                                            value={project.requested_at?.format('DD.MM.YYYY HH:mm:ss')}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid container justifyContent="space-between">
                        <Grid item style={{ marginLeft: 10 }}>
                            {!intern && initialData && (
                                <Button
                                    startIcon={<DoDisturb />}
                                    disabled={processing || readOnly}
                                    onClick={() => handleCancel(false)}
                                >
                                    Stornieren
                                </Button>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                <Grid item>
                                    <Button onClick={handleClose}>Abbrechen</Button>
                                </Grid>
                                <Grid item>
                                    <Button
                                        variant="contained"
                                        onClick={handleSubmit}
                                        disabled={processing || readOnly}
                                    >
                                        Speichern
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

            {children && (
                <span onClick={handleOpen}>
                    {children}
                </span>
            )}
        </React.Fragment>
    )
}

export default ProjectDialog;
