import React from 'react';
import { IUser } from '../../models/user';
import { Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { Edit } from '@mui/icons-material';

interface UserFormProps {
    user: IUser,
    onChange: (user: IUser) => void,
    showAll?: boolean,
}

function UserForm({ user, onChange, showAll }: UserFormProps) {
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);

    return (
        <Grid container spacing={2}>
            {showAll && (
                <React.Fragment>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            label="Vorname"
                            value={user.given_name}
                            onChange={event => onChange({ ...user, given_name: event.target.value })}
                            error={Boolean(formErrors.given_name)}
                            helperText={formErrors.given_name}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            label="Nachname"
                            value={user.surname}
                            onChange={event => onChange({ ...user, surname: event.target.value })}
                            error={Boolean(formErrors.surname)}
                            helperText={formErrors.surname}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            size="small"
                            label="Email"
                            value={user.email}
                            onChange={event => onChange({ ...user, email: event.target.value })}
                            error={Boolean(formErrors.email)}
                            helperText={formErrors.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            fullWidth
                            disabled
                            size="small"
                            type="password"
                            label="Passwort"
                            value="aaaaaaaaaaaaaaaaa"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <a href="https://id-ndr.urban-scope.de/auth/realms/NDR/account/#/security/signingin" target="_blank" rel="noreferrer">
                                            <Tooltip title="Passwort ändern">
                                                <IconButton>
                                                    <Edit />
                                                </IconButton>
                                            </Tooltip>
                                        </a>
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} />
                </React.Fragment>
            )}

            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Bereich / Abteilung"
                    value={user.region}
                    onChange={event => onChange({ ...user, region: event.target.value })}
                    error={Boolean(formErrors.region)}
                    helperText={formErrors.region}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Straße & Hausnummer"
                    value={user.address_street}
                    onChange={event => onChange({ ...user, address_street: event.target.value })}
                    error={Boolean(formErrors.address_street)}
                    helperText={formErrors.address_street}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="Haus"
                    value={user.address_house_number}
                    onChange={event => onChange({ ...user, address_house_number: event.target.value })}
                    error={Boolean(formErrors.address_house_number)}
                    helperText={formErrors.address_house_number}
                />
            </Grid>
            <Grid item xs={12} md={4}>
                <TextField
                    fullWidth
                    size="small"
                    label="Raumnummer"
                    value={user.address_room_number}
                    onChange={event => onChange({ ...user, address_room_number: event.target.value })}
                    error={Boolean(formErrors.address_room_number)}
                    helperText={formErrors.address_room_number}
                />
            </Grid>
            <Grid item xs={0} md={4} />
            <Grid item xs={12} md={4}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="PLZ"
                    value={user.address_zip}
                    onChange={event => onChange({ ...user, address_zip: event.target.value })}
                    error={Boolean(formErrors.address_zip)}
                    helperText={formErrors.address_zip}
                />
            </Grid>
            <Grid item xs={12} md={8}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Ort"
                    value={user.address_location}
                    onChange={event => onChange({ ...user, address_location: event.target.value })}
                    error={Boolean(formErrors.address_location)}
                    helperText={formErrors.address_location}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} md={6}>
                <TextField
                    required
                    fullWidth
                    size="small"
                    label="Telefonnummer"
                    value={user.phone_number}
                    onChange={event => onChange({ ...user, phone_number: event.target.value })}
                    error={Boolean(formErrors.phone_number)}
                    helperText={formErrors.phone_number}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Handynummer"
                    value={user.mobile_number}
                    onChange={event => onChange({ ...user, mobile_number: event.target.value })}
                    error={Boolean(formErrors.mobile_number)}
                    helperText={formErrors.mobile_number}
                />
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12}>
                <Typography variant="h6">Vertretung</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Name"
                    value={user.replacement_surname}
                    onChange={event => onChange({ ...user, replacement_surname: event.target.value })}
                    error={Boolean(formErrors.replacement_surname)}
                    helperText={formErrors.replacement_surname}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Vorname"
                    value={user.replacement_given_name}
                    onChange={event => onChange({ ...user, replacement_given_name: event.target.value })}
                    error={Boolean(formErrors.replacement_given_name)}
                    helperText={formErrors.replacement_given_name}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="E-Mail Adresse"
                    value={user.replacement_email}
                    onChange={event => onChange({ ...user, replacement_email: event.target.value })}
                    error={Boolean(formErrors.replacement_email)}
                    helperText={formErrors.replacement_email}
                />
            </Grid>
            <Grid item xs={12} md={6}>
                <TextField
                    fullWidth
                    size="small"
                    label="Telefonnummer"
                    value={user.replacement_phone_number}
                    onChange={event => onChange({ ...user, replacement_phone_number: event.target.value })}
                    error={Boolean(formErrors.replacement_phone_number)}
                    helperText={formErrors.replacement_phone_number}
                />
            </Grid>
            <Grid item xs={12}>
                <TextField
                    fullWidth
                    size="small"
                    label="Bereich / Abteilung"
                    value={user.replacement_region}
                    onChange={event => onChange({ ...user, replacement_region: event.target.value })}
                    error={Boolean(formErrors.replacement_region)}
                    helperText={formErrors.replacement_region}
                />
            </Grid>
        </Grid>
    )
}

export default UserForm;
