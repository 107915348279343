import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import ErrorHandler from '../../components/ErrorHandler';
import DataTable from '../../components/DataTable';
import { ITenantFetched } from '../../models/tenant';
import { getTenants } from '../../service/tenant.service';
import TenantDialog from '../../components/dialogs/TenantDialog';
import { GridColumns } from '@mui/x-data-grid';

function Tenants() {
    const [tenants, setTenants] = React.useState<ITenantFetched[]>();
    const [error, setError] = React.useState<string>();

    React.useEffect(() => fetchTenants(), []);

    const fetchTenants = (): void => {
        getTenants()
            .then(setTenants)
            .then(() => setError(undefined))
            .catch(err => setError(err.message));
    };

    const columns: GridColumns<ITenantFetched> = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.35,
        },
        {
            field: 'description',
            headerName: 'Beschreibung',
            flex: 0.4,
        },
        {
            field: '_id',
            headerName: 'Aktionen',
            flex: 0.15,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row }) => {
                const tenant = tenants?.find(el => el.id === row.id);

                if (!tenant) {
                    return null;
                }

                return (
                    <div>
                        <DeleteButton
                            confirm
                            resource={`tenants/${row.id}`}
                            onDelete={fetchTenants}
                            onError={setError}
                        />
                        <TenantDialog onFinished={fetchTenants} initialData={tenant}>
                            <Tooltip title="Bearbeiten">
                                <IconButton>
                                    <EditIcon />
                                </IconButton>
                            </Tooltip>
                        </TenantDialog>
                        <TenantDialog onFinished={fetchTenants} initialData={tenant} readOnly>
                            <Tooltip title="Details">
                                <IconButton>
                                    <InfoIcon />
                                </IconButton>
                            </Tooltip>
                        </TenantDialog>
                    </div>
                );
            },
        },
    ];

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/verwaltung"
                                            >
                                                <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Verwaltung
                                            </Link>
                                            <Typography color="text.primary">Kunden</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Kunden</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TenantDialog onFinished={fetchTenants}>
                                    <Button variant="contained">Kunden hinzufügen</Button>
                                </TenantDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!tenants ? (
                                <LinearProgress />
                            ) : (
                                <DataTable
                                    rows={tenants}
                                    columns={columns}
                                />
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Tenants;
