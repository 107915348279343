import { ITenant, ITenantFetched } from '../models/tenant';
import rest from '../rest/rest';

export const getTenants = async (): Promise<ITenantFetched[]> => {
    const { data } = await rest.get('tenants');

    return data;
};

export const addTenant = (tenant: ITenant): Promise<void> => {
    return rest.post('tenants', tenant);
};

export const updateTenant = (tenant: ITenantFetched): Promise<void> => {
    return rest.patch(`tenants/${tenant.id}`, tenant);
};

export const deleteTenant = (tenant: ITenantFetched) => {
    return rest.delete(`tenants/${tenant.id}`);
};