import { IProgram } from './program';
import { IStorageArea, IStorageAreaFetched } from './storageArea';
import { ITag, ITagFetched } from './tag';
import { IPackagingType } from './packagingType';
import { IProject } from './project';
import { ITenantFetched } from './tenant';

export interface IArticleAmount extends IArticleFetched {
    amount: number,
}

export interface IArticleProjectItem {
    id: number,
    amount: number | string,
    site_notes?: string,
    article: IArticleFetched,
    project: IProject,
    updated_at: string,
    created_at: string,
}

export interface StoredArticle {
    id: number,
    amount: number,
}

export interface IArticleFetched extends IArticle {
    id: number,
    category: IArticleCategory,
    program: IProgram,
    storage_areas: IStorageAreaFetched[],
    packaging_type: IPackagingType
    tags: ITagFetched[],
}

export interface IArticle {
    id?: number,
    zdf_id?: string,
    name: string,
    indoor: boolean,

    category?: IArticleCategory,
    program?: IProgram,
    tenant?: ITenantFetched,
    storage_areas?: IStorageArea[],
    tags?: ITag[],

    production_price?: number,
    selling_price?: number,
    cost_factor?: number,

    plain_height?: number,
    plain_width?: number,
    plain_depth?: number,

    packaged_height?: number,
    packaged_width?: number,
    packaged_depth?: number,

    packaged_weight?: number,
    plain_weight?: number,

    amount_total: number,
    amount_today: number,
    amount_per_package?: number,
    packaging_type?: IPackagingType,
    description?: string,

    production_date?: Date | null,
    clothing_sizes?: string,
    seasonal: boolean,
    merch: boolean,
    active: boolean,

    files: File[],
}

export interface IArticleCategory {
    id?: number,
    name: string,
    description?: string,
}

export const getDimensions = (article: IArticleFetched): string => {
    if (!article.plain_height || !article.plain_width || !article.plain_depth) {
        return '';
    }

    return `H: ${article.plain_height}cm B: ${article.plain_width}cm T: ${article.plain_depth}cm, `;
};
