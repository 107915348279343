import { IUser } from '../models/user';
import rest from '../rest/rest';

export const createUser = (user: IUser, frontend: string): Promise<void> => {
    return rest.post('users', { ...user, frontend });
};

export const updateUser = (user: IUser): Promise<void> => {
    return rest.post(`users/${user.id}`, user);
};

export const getUser = async (userId: string): Promise<IUser> => {
    const { data } = await rest.get(`users/${userId}`);

    return data;
};

export const getUserName = async (userId: string): Promise<string> => {
    const { data } = await rest.get(`users/${userId}/name`);

    return data.name;
};
