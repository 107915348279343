import React from 'react';
import {
    Alert,
    Breadcrumbs,
    Button,
    Container,
    Grid,
    IconButton,
    LinearProgress,
    Link,
    Paper,
    TextField,
    Typography,
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import Keycloak from 'keycloak-js';
import { Link as NavLink, useHistory, useLocation } from 'react-router-dom';
import Article from '../components/Article';
import { IArticleFetched } from '../models/article';
import ErrorHandler from '../components/ErrorHandler';
import { getArticles } from '../service/article.service';
import SelectTenant from '../components/data/SelectTenant';
import { IProgram } from '../models/program';
import rest from '../rest/rest';
import { ArrowBack } from '@mui/icons-material';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Articles({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    const history = useHistory();
    const queryParams = useQuery();
    const [articles, setArticles] = React.useState<IArticleFetched[]>();
    const [query, setQuery] = React.useState<string>('');
    const [error, setError] = React.useState<string>();
    const [tenant, setTenant] = React.useState<number>();
    const [program, setProgram] = React.useState<IProgram>();

    React.useEffect(() => {
        const programId = queryParams.get('program');

        if (!programId) {
            return;
        }

        rest.get(`article/brandingPrograms/${programId}`)
            .then(res => setProgram(res.data))
            .catch(console.error);
    }, [queryParams]);

    React.useEffect(() => {
        if (!tenant) {
            return;
        }

        getArticles(query, tenant, queryParams.get('program') ? Number(queryParams.get('program')) : undefined)
            .then(setArticles)
            .catch(err => setError(err.response?.data?.message ?? err.message));
    }, [query, tenant]);

    return (
        <Container maxWidth="lg">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Typography color="text.primary">Artikel</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {keyCloak.hasRealmRole('verwaltung_artikel') && (
                                <Grid item>
                                    <NavLink to="/manage/artikel">
                                        <Button variant="contained" startIcon={<SettingsIcon />}>Artikel verwalten</Button>
                                    </NavLink>
                                </Grid>
                            )}
                            {program ? (
                                <Grid item xs={12}>
                                    <Grid container spacing={1} alignItems="center">
                                        <Grid item>
                                            <IconButton style={{ marginBottom: 7 }} onClick={() => history.goBack()}>
                                                <ArrowBack/>
                                            </IconButton>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h5" gutterBottom>{program.name}</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid item>
                                    <SelectTenant
                                        buttonGroup
                                        hideCreateButton
                                        value={tenant}
                                        onChange={tenant => setTenant(tenant?.id)}
                                        onFirst={tenant => setTenant(tenant.id)}
                                        allowAll
                                        onAll={() => setTenant(-1)}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    label="Suchen ..."
                                    variant="standard"
                                    onChange={event => setQuery(event.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <ErrorHandler error={error}>
                                    {!articles ? (
                                        <LinearProgress />
                                    ) : (
                                        <Grid container spacing={2} direction="row">
                                            {articles.length === 0 ? (
                                                <Grid item xs={12}>
                                                    <Alert severity="info">
                                                        Es wurden keine passenden Artikel gefunden.
                                                    </Alert>
                                                </Grid>
                                            ) : articles.map(article => (
                                                <Grid item xs={12} key={`${article.name}${article.id}`}>
                                                    <Article article={article} />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    )}
                                </ErrorHandler>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Articles;
