import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';
import ProgramDialog from '../../components/dialogs/ProgramDialog';
import { IProgram } from '../../models/program';
import rest from '../../rest/rest';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import ErrorHandler from '../../components/ErrorHandler';
import DataTable from '../../components/DataTable';
import ImagePreview from '../../components/ImagePreview';

function Programs() {
    const [programs, setPrograms] = React.useState<IProgram[]>();
    const [error, setError] = React.useState<string>();

    const fetchData = (): void => {
        rest.get('article/brandingPrograms')
            .then(res => {
                setError(undefined);
                setPrograms(res.data);
            })
            .catch(err => setError(err.message));
    };

    React.useEffect(fetchData, []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/verwaltung"
                                            >
                                                <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Verwaltung
                                            </Link>
                                            <Typography color="text.primary">Programme</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Programme</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <ProgramDialog onFinished={fetchData}>
                                    <Button startIcon={<AddIcon />} variant="contained">Programm hinzufügen</Button>
                                </ProgramDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!programs ? (
                                <LinearProgress />
                            ) : (
                                <DataTable
                                    rows={programs}
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            flex: 0.1,
                                        },
                                        {
                                            field: 'icon',
                                            headerName: 'Icon',
                                            flex: 0.1,
                                            renderCell: ({ row }: { row: IProgram }) => (
                                                row.file?.id ? <ImagePreview id={row.file.id} maxHeight="32" /> : null
                                            ),
                                        },
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            flex: 0.2,
                                        },
                                        {
                                            field: 'tenant',
                                            headerName: 'Kunde',
                                            flex: 0.1,
                                            renderCell: ({ row }: { row: IProgram }) => row.tenant?.name ?? '',
                                        },
                                        {
                                            field: 'description',
                                            headerName: 'Beschreibung',
                                            flex: 0.4,
                                        },
                                        {
                                            field: '_id',
                                            headerName: 'Aktionen',
                                            flex: 0.1,
                                            align: 'right',
                                            headerAlign: 'right',
                                            renderCell: ({ row }: { row: IProgram }) => {
                                                const program = programs.find(el => el.id === row.id);

                                                return (
                                                    <div>
                                                        <DeleteButton
                                                            resource={`article/brandingPrograms/${row.id}`}
                                                            onDelete={fetchData}
                                                            onError={setError}
                                                        />
                                                        <ProgramDialog onFinished={fetchData} initialData={program}>
                                                            <Tooltip title="Bearbeiten">
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ProgramDialog>
                                                        <ProgramDialog onFinished={fetchData} initialData={program} readOnly>
                                                            <Tooltip title="Details">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </ProgramDialog>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Programs;
