import axios from 'axios';
import config from '../config.json';
import { onError } from './interceptor';

const baseURL = process.env.NODE_ENV === 'development' ?
    config.api.endpoints.dev : config.api.endpoints.prod;

const rest = axios.create({
    baseURL,
    timeout: 8000,
    maxRedirects: 0,
});

rest.interceptors.response.use(response => response, err => onError(err));

export default rest;
