import React from 'react';
import { getCartCount } from '../service/cart.service';
import { styled } from '@mui/material/styles';
import { Badge, BadgeProps, IconButton, Tooltip } from '@mui/material';
import { ShoppingCart } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
        right: 4,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

function ProjectCartCount({ projectId }: { projectId: number }) {
    const history = useHistory();
    const [count, setCount] = React.useState<number>(0);

    React.useEffect(() => {
        getCartCount(projectId)
            .then(setCount);
    }, []);

    return (
        <StyledBadge color="primary" badgeContent={count}>
            <Tooltip title="Warenkorb ansehen">
                <IconButton onClick={() => history.push(`/projekte/${projectId}`)}>
                    <ShoppingCart />
                </IconButton>
            </Tooltip>
        </StyledBadge>
    );
}

export default ProjectCartCount;
