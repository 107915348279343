import React from 'react';
import { CircularProgress, Grid } from '@mui/material';
import rest from '../rest/rest';

interface ImagePreviewProps {
    id: number,
    maxHeight?: string,
}

function ImagePreview({ id, maxHeight }: ImagePreviewProps) {
    const [url, setUrl] = React.useState<string>();

    React.useEffect(() => {
        rest.get(`article/files/${id}/url`)
            .then(res => setUrl(res.data.url))
            .catch(console.error);
    }, [id]);

    if (!url) {
        return (
            <Grid container justifyContent="center" alignItems="center" style={{ width: '100%', height: '100%' }}>
                <Grid item>
                    <CircularProgress />
                </Grid>
            </Grid>
        );
    }

    return (
        <img
            loading="lazy"
            height={maxHeight ?? 'auto'}
            width={maxHeight ? 'auto' : '100%'}
            src={url}
            alt="Uploaded File"
        />
    );
}

export default ImagePreview;
