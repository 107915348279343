import React, { ReactElement } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    link: {
        textDecoration: 'underline',
    },
});

export const Footer = (): ReactElement => {
    const classes = useStyles();

    return (
        <Box
            sx={{
                mt: `5vh`,
                width: '100%',
                height: 'auto',
                paddingTop: '1rem',
                paddingBottom: '1rem',
            }}
        >
            <Container maxWidth="xl">
                <Grid container justifyContent="center">
                    <Grid item>
                        <Typography color="textSecondary" variant="subtitle1" style={{ fontSize: '0.9rem' }}>
                            © {new Date().getFullYear()} - <a className={classes.link} href="https://urban-scope.de">urban!scope GmbH</a> - powered by <a className={classes.link} href="https://www.jamoin.de">JAMOIN GmbH</a>
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;