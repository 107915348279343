import React from 'react';
import { Autocomplete, AutocompleteValue, Grid, IconButton, TextField } from '@mui/material';
import rest from '../../rest/rest';
import { IStorageArea } from '../../models/storageArea';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import StorageAreaDialog from '../dialogs/StorageAreaDialog';
import { ErrorAble } from '../../models/components';

interface SelectWarehouseProps extends ErrorAble {
    onChange: (id?: IStorageArea[]) => void,
    values?: number[],
    readOnly?: boolean,
}

function SelectStorageAreas({ onChange, values, readOnly, error }: SelectWarehouseProps) {
    const [selected, setSelected] = React.useState<IStorageArea[]>([]);
    const [storageAreas, setStorageAreas] = React.useState<IStorageArea[]>([]);

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected), [selected]);

    const fetchData = () => {
        rest.get('logistic/storageAreas')
            .then(res => {
                setStorageAreas(res.data);

                if (values) {
                    setSelected(res.data.filter((el: any) => values.includes(el.id)))
                }
            })
            .catch(console.error);
    };

    function getSelected() {
        if (storageAreas.length > 0 && selected) {
            return selected;
        }

        return [];
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    disablePortal
                    loading={storageAreas.length < 1}
                    options={storageAreas}
                    disabled={readOnly}
                    getOptionLabel={storageArea => `${storageArea.name} (${storageArea.warehouse?.name})`}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Lagerorte"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, values: AutocompleteValue<any, any, any, any>) => {
                        setSelected(values);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <StorageAreaDialog onFinished={fetchData}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </StorageAreaDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectStorageAreas;
