import { IProject } from '../models/project';
import rest from '../rest/rest';
import dayjs from 'dayjs';

export const getProjects = async (query?: string, type: string = 'current', year?: number, status?: string, tenant?: number): Promise<IProject[]> => {
    const { data } = await rest.get(`projects?query=${query ?? ''}&type=${type}&year=${year ?? ''}&status=${status ?? ''}&tenant=${tenant ?? ''}`);

    return data.map((project: IProject) => ({
        ...project,

        // initialize dayjs objects
        start_date: dayjs(project.start_date),
        end_date: dayjs(project.end_date),
        delivery_date: dayjs(project.delivery_date),
        pickup_date: project.pickup_date ? dayjs(project.pickup_date) : null,
        requested_at: project.requested_at ? dayjs(project.requested_at): null,
        created_at: dayjs(project.created_at),
        updated_at: dayjs(project.updated_at),
    }));
};

export const getProject = async (projectId: number): Promise<IProject> => {
    const { data } = await rest.get(`projects/${projectId}`);

    return {
        ...data,

        // initialize dayjs objects
        start_date: dayjs(data.start_date),
        end_date: dayjs(data.end_date),
        delivery_date: dayjs(data.delivery_date),
        pickup_date: data.pickup_date ? dayjs(data.pickup_date) : null,
        requested_at: data.requested_at ? dayjs(data.requested_at): null,
        created_at: dayjs(data.created_at),
        updated_at: dayjs(data.updated_at),
    }
};

export const createProject = (project: IProject): Promise<void> => {
    return rest.post('projects', project);
};

export const updateProject = (project: IProject): Promise<void> => {
    return rest.post(`projects/${project.id}`, project);
};

export const cancelProject = (projectId: number): Promise<void> => {
    return rest.post(`projects/${projectId}/cancel`);
}

export const requestProject = (projectId: number): Promise<void> => {
    return rest.post(`projects/${projectId}/request`);
};
