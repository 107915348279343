import React from 'react';
import rest from '../rest/rest';
import { IconButton, Tooltip } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';

interface OpenFileProps {
    id: number,
}

function OpenFile({ id }: OpenFileProps) {
    const [url, setUrl] = React.useState<string>();

    React.useEffect(() => {
        rest.get(`article/files/${id}/url`)
            .then(res => setUrl(res.data.url))
            .catch(console.error);
    }, []);

    if (!url) {
        return (
            <IconButton>
                <DownloadIcon />
            </IconButton>
        );
    }

    return (
        <a href={url} target="_blank" rel="noreferrer">
            <Tooltip title="Datei ansehen / herunterladen">
                <IconButton>
                    <DownloadIcon />
                </IconButton>
            </Tooltip>
        </a>
    )
}

export default OpenFile;
