import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';
import rest from '../../rest/rest';
import Paper from '@mui/material/Paper';
import InfoIcon from '@mui/icons-material/Info';
import EditIcon from '@mui/icons-material/Edit';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import { ITag, ITagFetched } from '../../models/tag';
import TagDialog from '../../components/dialogs/TagDialog';
import ErrorHandler from '../../components/ErrorHandler';
import DataTable from '../../components/DataTable';

function Tags() {
    const [tags, setTags] = React.useState<ITag[]>();
    const [error, setError] = React.useState<string>();

    React.useEffect(() => fetchTags(), []);

    const fetchTags = (): void => {
        rest.get('article/tags')
            .then(res => {
                setError(undefined);
                setTags(res.data);
            })
            .catch(err => setError(err.message));
    };

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/verwaltung"
                                            >
                                                <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Verwaltung
                                            </Link>
                                            <Typography color="text.primary">Tags</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Tags</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TagDialog onFinished={fetchTags}>
                                    <Button variant="contained">Tag hinzufügen</Button>
                                </TagDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!tags ? (
                                <LinearProgress />
                            ) : (
                                <DataTable
                                    rows={tags}
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            flex: 0.1,
                                        },
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            flex: 0.8
                                        },
                                        {
                                            field: '_id',
                                            headerName: 'Aktionen',
                                            flex: 0.1,
                                            align: 'right',
                                            headerAlign: 'right',
                                            renderCell: ({ row }: { row: ITagFetched }) => {
                                                const tag = tags.find(el => el.id === row.id);

                                                return (
                                                    <div>
                                                        <DeleteButton
                                                            resource={`article/tags/${row.id}`}
                                                            onDelete={fetchTags}
                                                            onError={setError}
                                                        />
                                                        <TagDialog onFinished={fetchTags} initialData={tag}>
                                                            <Tooltip title="Bearbeiten">
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TagDialog>
                                                        <TagDialog onFinished={fetchTags} initialData={tag} readOnly>
                                                            <Tooltip title="Details">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </TagDialog>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Tags;
