import { IProgram } from '../models/program';
import { Grid, Paper, Typography } from '@mui/material';
import ImagePreview from './ImagePreview';
import noImage from '../images/no_image.png';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface ProgramCardProps {
    program: IProgram,
}

function ProgramCard({ program }: ProgramCardProps) {
    const history = useHistory();

    return (
        <Paper variant="outlined" sx={{ p: 2 }} style={{ cursor: 'pointer' }} onClick={() => history.push(`/artikel?program=${program.id}`)}>
            <Grid direction="column" container justifyContent="center" alignItems="center" style={{ height: 150 }}>
                <Grid item>
                    {program.file?.id ? (
                        <ImagePreview id={program.file.id}/>
                    ) : (
                        <img
                            src={noImage}
                            width="100%"
                            height="auto"
                            alt="Kein Bild"
                        />
                    )}
                </Grid>
            </Grid>
            <Typography variant="body1">
                {program.name}
            </Typography>
        </Paper>
    );
}

export default ProgramCard;