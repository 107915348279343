import React from 'react';
import { Autocomplete, AutocompleteValue, Grid, IconButton, TextField } from '@mui/material';
import { IWarehouse } from '../../models/warehouse';
import rest from '../../rest/rest';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import WarehouseDialog from '../dialogs/WarehouseDialog';

interface SelectWarehouseProps {
    onChange: (id?: number) => void,
    value?: IWarehouse,
    readOnly?: boolean,
}

function SelectWarehouse({ onChange, value, readOnly }: SelectWarehouseProps) {
    const [warehouses, setWarehouses] = React.useState<IWarehouse[]>([]);

    React.useEffect(() => fetchData(), []);

    const fetchData = () => {
        rest.get('logistic/warehouses')
            .then(res => setWarehouses(res.data))
            .catch(console.error);
    };

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={warehouses.length < 1}
                    options={warehouses}
                    disabled={readOnly}
                    getOptionLabel={warehouse => `${warehouse.name} (${warehouse.street})`}
                    renderInput={(params) => <TextField {...params} required label="Lager" />}
                    defaultValue={value}
                    onChange={(event, value: AutocompleteValue<any, any, any, any>) => {
                        if (!value) {
                            onChange(undefined);
                            return;
                        }
                        onChange(value.id);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <WarehouseDialog onFinished={fetchData}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </WarehouseDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectWarehouse;
