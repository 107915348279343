import React, { FormEvent } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { IProgram } from '../../models/program';
import { useDispatch, useSelector } from 'react-redux';
import rest from '../../rest/rest';
import { clearFormErrors } from '../../actions/formError.actions';
import FileUploadButton from '../FileUploadButton';
import ImagePreview from '../ImagePreview';
import SelectTenant from '../data/SelectTenant';

interface ProgramDialogProps {
    onFinished: () => void,
    initialData?: IProgram,
    children: any,
    readOnly?: boolean
}

const defaultData: IProgram = {
    name: '',
    description: '',
};

function ProgramDialog({ onFinished, initialData, children, readOnly }: ProgramDialogProps) {
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer).errors;
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [open, setOpen] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IProgram>(initialData || defaultData);
    const [file, setFile] = React.useState<File>();

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();

        setProcessing(true);
        dispatch(clearFormErrors());

        if (initialData) {
            const updated: any = Object.assign({}, data);

            if (updated.name === initialData.name) {
                delete updated.name;
            }
            if (!updated.tenant_id) {
                updated.tenant_id = null;
            }

            try {
                await rest.patch(`article/brandingPrograms/${data.id}`, updated);

                if (file) {
                    const formData = new FormData();
                    formData.append('file', file);

                    await rest.post(`article/brandingPrograms/${data.id}/file`, formData);
                }

                setProcessing(false);
                setOpen(false);
                onFinished();

            } catch (err) {
                setProcessing(false);
                console.error(err);
            }
            return;
        }

        try {
            await rest.post('article/brandingPrograms', data);

            setProcessing(false);
            onFinished();
            handleClose();
            setData(defaultData);
        } catch (err) {
            setProcessing(false);
            console.error(err);
        }
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Programm hinzufügen</DialogTitle>
                <form onSubmit={handleSubmit}>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    required
                                    label="Name"
                                    size="small"
                                    value={data.name}
                                    onChange={event => setData({ ...data, name: event.target.value })}
                                    error={Boolean(formErrors.name)}
                                    helperText={formErrors.name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    disabled={readOnly}
                                    fullWidth
                                    label="Beschreibung"
                                    size="small"
                                    multiline
                                    rows={2}
                                    maxRows={4}
                                    value={data.description}
                                    onChange={event => setData({ ...data, description: event.target.value })}
                                    error={Boolean(formErrors.description)}
                                    helperText={formErrors.description}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SelectTenant
                                    onChange={tenant => setData({ ...data, tenant_id: tenant?.id })}
                                    value={data.tenant_id}
                                    error={formErrors.tenant_id}
                                />
                            </Grid>
                            {initialData?.file?.id && (
                                <Grid item xs={12}>
                                    <ImagePreview id={initialData.file.id} maxHeight="64" />
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <FileUploadButton
                                    onChange={files => {
                                        if (files.length === 1) {
                                            setFile(files[0]);
                                            return;
                                        }
                                        setFile(undefined);
                                    }}
                                    files={file ? [file] : []}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Abbrechen</Button>
                        {!readOnly && (
                            <Button disabled={processing} type="submit" variant="contained">Speichern</Button>
                        )}
                    </DialogActions>
                </form>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default ProgramDialog;
