import { IContactPerson } from '../models/contact';
import rest from '../rest/rest';

export const getContactPersons = async (): Promise<IContactPerson[]> => {
    const { data } = await rest.get('contacts');

    return data;
};

export const createContactPerson = async (contact: IContactPerson): Promise<IContactPerson> => {
    const { data } = await rest.post(`contacts`, contact);

    return data;
};
