import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, TextField, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import ArticleDialog from '../../components/dialogs/ArticleDialog';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import ErrorHandler from '../../components/ErrorHandler';
import { IArticleFetched } from '../../models/article';
import ExportArticles from '../../components/dialogs/ExportArticles';
import DataTable from '../../components/DataTable';
import { GridColumns, GridComparatorFn } from '@mui/x-data-grid';
import { getArticles } from '../../service/article.service';
import SelectTenant from '../../components/data/SelectTenant';
import Keycloak from 'keycloak-js';

const zdfIdComparator: GridComparatorFn<string> = (v1, v2) => {
    if (!v1 || !v2) {
        return -1;
    }

    return v1.localeCompare(v2, undefined, { numeric: true, sensitivity: 'base' });
};

function ManageArticles({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    const [articles, setArticles] = React.useState<IArticleFetched[]>();
    const [tenant, setTenant] = React.useState<number>();
    const [query, setQuery] = React.useState<string>('');
    const [error, setError] = React.useState<string>();

    React.useEffect(() => fetchData(), [query, tenant]);

    const fetchData = (): void => {
        getArticles(query, tenant)
            .then(res => {
                setError(undefined);
                setArticles(res);
            })
            .catch(err => setError(err.message));
    };

    const columns: GridColumns<IArticleFetched> = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
        },
        {
            field: 'zdf_id',
            headerName: 'NDR-ID',
            flex: 0.1,
            sortComparator: zdfIdComparator,
        },
        {
            field: 'tenant',
            headerName: 'Kunde',
            flex: 0.2,
            valueGetter: params => params.row.tenant?.name,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.5,
        },
        {
            field: '_id',
            headerName: 'Aktionen',
            flex: 0.1,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row }: { row: IArticleFetched }) => {
                if (!articles) {
                    return null;
                }

                const article = articles.find(el => el.id === row.id);

                return (
                    <div>
                        <ArticleDialog onFinished={fetchData} initialData={article}>
                            <Tooltip title="Bearbeiten">
                                <IconButton>
                                    <EditIcon/>
                                </IconButton>
                            </Tooltip>
                        </ArticleDialog>
                        {keyCloak.hasRealmRole('artikel_löschen') && (
                            <DeleteButton resource={`article/articles/${row.id}`} confirm onDelete={fetchData} />
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit"/>
                                        Dashboard
                                    </Link>
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/verwaltung"
                                    >
                                        <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit"/>
                                        Verwaltung
                                    </Link>
                                    <Typography color="text.primary">Artikel</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5">Artikelverwaltung</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid item xs={12}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Grid container spacing={2}>
                                        {keyCloak.hasRealmRole('artikel_hinzufügen') && (
                                            <Grid item>
                                                <ArticleDialog onFinished={fetchData}>
                                                    <Button variant="contained" startIcon={<AddIcon/>}>Artikel hinzufügen</Button>
                                                </ArticleDialog>
                                            </Grid>
                                        )}
                                        <Grid item style={{ width: 300 }}>
                                            <SelectTenant onChange={tenant => setTenant(tenant?.id)} hideCreateButton />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                {keyCloak.hasRealmRole('artikel_exportieren') && (
                                    <Grid item>
                                        <ExportArticles />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!articles ? (
                                <LinearProgress/>
                            ) : (
                                <Grid container spacing={4}>
                                    <Grid item xs={12}>
                                        <TextField
                                            fullWidth
                                            label="Suche"
                                            size="small"
                                            variant="standard"
                                            value={query}
                                            onChange={event => setQuery(event.target.value)}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <DataTable
                                            rows={articles}
                                            columns={columns}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ManageArticles;
