import React from 'react';
import rest from '../rest/rest';
import { IconButton, Tooltip } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { setPrompt } from '../actions/prompt.actions';
import { useDispatch } from 'react-redux';

interface DeleteButtonProps {
    resource: string,
    onDelete: () => void,
    title?: string,
    size?: any,
    confirm?: boolean,
    onError?: (message: string) => void,
}

function DeleteButton({ resource, onDelete, title, size, confirm, onError }: DeleteButtonProps) {
    const dispatch = useDispatch();
    const [processing, setProcessing] = React.useState<boolean>(false);

    const handleDelete = (override?: boolean) => {
        if (confirm && !override) {
            dispatch(setPrompt('Wirklich löschen?', 'Alle verknüpften Daten werden gelöscht.', () => handleDelete(true)));
            return;
        }

        setProcessing(true);

        rest.delete(resource)
            .then(onDelete)
            .then(() => setProcessing(false))
            .catch(err => {
                setProcessing(false);

                if (onError) {
                    if (err.response?.data?.message) {
                        onError(err.response.data.message);
                    } else {
                        onError(err.message);
                    }
                }

                console.error(err);
            });
    };

    return (
        <Tooltip title={title || 'Löschen'}>
            <IconButton onClick={() => handleDelete()} disabled={processing}>
                <DeleteIcon fontSize={size || 'medium'} />
            </IconButton>
        </Tooltip>
    );
}

export default DeleteButton;
