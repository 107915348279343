import { Dayjs } from 'dayjs';
import { IUser } from './user';
import { IAddress } from './address';
import { IContactPerson } from './contact';

export interface IProject {
    id?: number,
    name: string,
    job_number?: string,
    status?: string,
    start_date: Dayjs | null,
    end_date: Dayjs | null,
    delivery_date: Dayjs | null,
    pickup_date: Dayjs | null,
    delivery_instructions?: string,
    intern_notes?: string,
    updated_at?: Dayjs,
    created_at?: Dayjs,
    requested_at?: Dayjs,

    user?: IUser,
    address?: IAddress,
    contact_person?: IContactPerson,

    user_id?: string,
    address_id?: number,
    contact_id?: number,
}

export const ProjectStatus: Record<string, string> = {
    Offen: 'Offen',
    Angefragt: 'Angefragt',
    Beantwortet: 'Beantwortet / KVA erstellt',
    Bestellt: 'Bestellt / Bestätigt',
    Kommissionierung: 'Kommissionierung',
    Abgeholt: 'Abgeholt / Unterwegs',
    Zurueckgeliefert: 'Zurueckgeliefert',
    Geprueft: 'Geprüft / Zurückgeführt',
    Abgeschlossen: 'Abgeschlossen',
    Storniert: 'Storniert',
};
