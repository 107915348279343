import React from 'react';
import { Autocomplete, AutocompleteValue, Grid, IconButton, TextField } from '@mui/material';
import rest from '../../rest/rest';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ITag } from '../../models/tag';
import TagDialog from '../dialogs/TagDialog';

interface SelectTagsProps {
    onChange: (id?: ITag[]) => void,
    values?: number[],
    readOnly?: boolean,
}

function SelectTags({ onChange, values, readOnly }: SelectTagsProps) {
    const [selected, setSelected] = React.useState<ITag[]>([]);
    const [tags, setTags] = React.useState<ITag[]>([]);

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected), [selected]);

    const fetchData = () => {
        rest.get('article/tags')
            .then(res => {
                setTags(res.data);

                if (values) {
                    setSelected(res.data.filter((el: any) => values.includes(el.id)))
                }
            })
            .catch(console.error);
    };

    function getSelected() {
        if (tags.length > 0 && selected) {
            return selected;
        }

        return [];
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    multiple
                    disablePortal
                    loading={tags.length < 1}
                    options={tags}
                    disabled={readOnly}
                    getOptionLabel={tag => tag.name}
                    value={getSelected()}
                    renderInput={(params) => <TextField {...params} label="Tags" />}
                    onChange={(event, value: AutocompleteValue<any, any, any, any>) => {
                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <TagDialog onFinished={fetchData}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </TagDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectTags;
