import React from 'react';
import { Container, Grid, Paper, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import SelectCard from '../components/SelectCard';
import { useHistory } from 'react-router-dom';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import PostAddIcon from '@mui/icons-material/PostAdd';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
    },
    buttons: {
        marginTop: theme.spacing(3),
    },
}));

interface DashboardProps {
    name: string,
}

function Dashboard({ name }: DashboardProps) {
    const classes = useStyles();
    const history = useHistory();

    return (
        <Container maxWidth="md" style={{ marginTop: '5%' }}>
            <Paper style={{ padding: 20, paddingBottom: 30 }} variant="elevation" elevation={4}>
                <Grid container spacing={2}>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                        <Grid container spacing={4} justifyContent="center">
                            <Grid item>
                                <Typography variant="h4">Willkommen, {name}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4} justifyContent="center" className={classes.buttons}>
                            <Grid item>
                                <SelectCard
                                    selected={false}
                                    onClick={() => history.push('/artikel')}
                                    title="Artikel"
                                    icon={<ShoppingCartIcon />}
                                />
                            </Grid>
                            <Grid item>
                                <SelectCard
                                    selected={false}
                                    onClick={() => history.push('/projekte')}
                                    title="Projekte"
                                    icon={<PostAddIcon />}
                                />
                            </Grid>
                            <Grid item>
                                <SelectCard
                                    selected={false}
                                    onClick={() => history.push('/profil')}
                                    title="Konto"
                                    icon={<AccountBoxIcon />}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Dashboard;
