import { IArticleFetched } from '../models/article';
import rest from '../rest/rest';

export const getArticles = async (query?: string, tenantId?: number, programId?: number): Promise<IArticleFetched[]> => {

    if (!programId) {
        const { data } = await rest.get(tenantId ? `article/articles?query=${query}&tenant=${tenantId}` : `article/articles?query=${query}`);

        return data;
    }

    const { data } = await rest.get(tenantId ? `article/articles?query=${query}&tenant=${tenantId}&program=${programId}` : `article/articles?query=${query}&program=${programId}`);

    return data;
};

export const getArticle = async (articleId: number): Promise<IArticleFetched> => {
    const { data } = await rest.get(`article/articles/${articleId}`);

    return data;
};
