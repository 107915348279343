import React from 'react';
import { Grid, Paper, Typography } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: any) => ({
    paper: {
        width: 200,
        height: 90,
        padding: 11,
        borderStyle: 'solid',
        borderWidth: 1,
        cursor: 'pointer',
    },
    active: {
        borderColor: theme.palette.primary.main,
    },
}));

interface SelectCardProps {
    selected: boolean,
    onClick: () => void,
    title: string,
    icon: any,
}

const SelectCard = ({ selected, onClick, title, icon }: SelectCardProps) => {
    const classes = useStyles();
    const [sel, setSel] = React.useState<boolean>(selected);

    return (
        <Paper variant="outlined" className={clsx(classes.paper, sel && classes.active)} onClick={onClick} onMouseEnter={() => setSel(true)} onMouseLeave={() => setSel(false)}>
            <Grid container direction="column" justifyContent="space-between">
                <Grid item>
                    {icon}
                </Grid>
                <Grid item>
                    <Typography variant="h5" style={{ fontSize: '1.5em', color: '#505050' }}>{title}</Typography>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default SelectCard;
