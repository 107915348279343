import React from 'react';
import { Breadcrumbs, Button, Container, Grid, IconButton, LinearProgress, Link, Tooltip, Typography } from '@mui/material';
import rest from '../../rest/rest';
import Paper from '@mui/material/Paper';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';
import { IPackagingType, IPackagingTypeFetched } from '../../models/packagingType';
import PackagingTypesDialog from '../../components/dialogs/PackagingTypesDialog';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteButton from '../../components/DeleteButton';
import ErrorHandler from '../../components/ErrorHandler';
import DataTable from '../../components/DataTable';

function PackagingTypes() {
    const [packagingTypes, setPackagingTypes] = React.useState<IPackagingType[]>();
    const [error, setError] = React.useState<string>();

    const fetchData = (): void => {
        rest.get('logistic/packagingTypes')
            .then(res => {
                setError(undefined);
                setPackagingTypes(res.data);
            })
            .catch(err => setError(err.message));
    };

    React.useEffect(fetchData, []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/verwaltung"
                                            >
                                                <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Verwaltung
                                            </Link>
                                            <Typography color="text.primary">Verpackungsarten</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Verpackungsarten</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <PackagingTypesDialog onFinished={fetchData}>
                                    <Button startIcon={<AddIcon />} variant="contained">Verpackungsart hinzufügen</Button>
                                </PackagingTypesDialog>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <ErrorHandler error={error}>
                            {!packagingTypes ? (
                                <LinearProgress />
                            ) : (
                                <DataTable
                                    rows={packagingTypes}
                                    columns={[
                                        {
                                            field: 'id',
                                            headerName: 'ID',
                                            flex: 0.1,
                                        },
                                        {
                                            field: 'name',
                                            headerName: 'Name',
                                            flex: 0.5,
                                        },
                                        {
                                            field: 'dimensions',
                                            headerName: 'Maß',
                                            flex: 0.3,
                                            renderCell: ({ row }: { row: IPackagingTypeFetched }) => row.height ? `${row.height}cm x ${row.width}cm x ${row.depth}cm` : '',
                                        },
                                        {
                                            field: '_id',
                                            headerName: 'Aktionen',
                                            flex: 0.1,
                                            align: 'right',
                                            headerAlign: 'right',
                                            renderCell: ({ row }: { row: IPackagingTypeFetched }) => {
                                                const type = packagingTypes.find(el => el.id === row.id);

                                                return (
                                                    <div>
                                                        <DeleteButton
                                                            resource={`logistic/packagingTypes/${row.id}`}
                                                            onDelete={fetchData}
                                                            onError={setError}
                                                        />
                                                        <PackagingTypesDialog onFinished={fetchData} initialData={type}>
                                                            <Tooltip title="Bearbeiten">
                                                                <IconButton>
                                                                    <EditIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </PackagingTypesDialog>
                                                        <PackagingTypesDialog onFinished={fetchData} initialData={type} readOnly>
                                                            <Tooltip title="Details">
                                                                <IconButton>
                                                                    <InfoIcon />
                                                                </IconButton>
                                                            </Tooltip>
                                                        </PackagingTypesDialog>
                                                    </div>
                                                );
                                            },
                                        },
                                    ]}
                                />
                            )}
                        </ErrorHandler>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default PackagingTypes;
