import React from 'react';
import { Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import { IProgram } from '../models/program';
import rest from '../rest/rest';
import ProgramCard from '../components/ProgramCard';

function ProgramOverview() {
    const [programs, setPrograms] = React.useState<IProgram[]>([]);

    React.useEffect(() => {
        rest.get('article/brandingPrograms')
            .then(res => {
                setPrograms(res.data);
            })
            .catch(console.error);
    }, []);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Breadcrumbs aria-label="breadcrumb">
                                            <Link
                                                underline="hover"
                                                sx={{ display: 'flex', alignItems: 'center' }}
                                                color="inherit"
                                                href="/"
                                            >
                                                <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                                Dashboard
                                            </Link>
                                            <Typography color="text.primary">Markenübersicht</Typography>
                                        </Breadcrumbs>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Typography variant="h5">Markenübersicht</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            {programs.map(program => (
                                <Grid item key={`program${program.id}`} xs={6} sm={2}>
                                    <ProgramCard program={program} />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ProgramOverview;
