import React from 'react';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import rest from '../../rest/rest';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ArticleCategoryDialog from '../dialogs/ArticleCategoryDialog';
import { ErrorAble } from '../../models/components';
import { IArticleCategory } from '../../models/article';

interface SelectWarehouseProps extends ErrorAble {
    onChange: (category?: IArticleCategory) => void,
    value?: number,
    readOnly?: boolean,
}

function SelectCategory({ onChange, value, readOnly, error }: SelectWarehouseProps) {
    const [selected, setSelected] = React.useState<IArticleCategory>();
    const [categories, setCategories] = React.useState<IArticleCategory[]>([]);

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected), [selected]);

    const fetchData = () => {
        rest.get('article/categories')
            .then(res => {
                setCategories(res.data);

                if (value) {
                    setSelected(res.data.filter((cat: any) => cat.id === value)[0]);
                }
            })
            .catch(console.error);
    };

    function getSelected() {
        if (categories.length > 0 && selected) {
            return selected;
        }

        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={categories.length < 1}
                    options={categories}
                    disabled={readOnly}
                    getOptionLabel={category => category.name}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Kategorie"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, value: any) => {
                        if (!value) {
                            setSelected(undefined);
                            return;
                        }

                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <ArticleCategoryDialog onFinished={fetchData}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </ArticleCategoryDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectCategory;
