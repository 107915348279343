import React from 'react';
import { IAddress } from '../../models/address';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { clearFormErrors } from '../../actions/formError.actions';
import { createAddress } from '../../service/address.service';

interface AddAddressDialogProps {
    onFinished: (address: IAddress) => void,
    children: JSX.Element,
}

const initialData: IAddress = {
    location: '',
    address_street: '',
    address_house_number: '',
    address_zip: '',
    address_location: '',
};

function AddAddressDialog({ onFinished, children }: AddAddressDialogProps) {
    const dispatch = useDispatch();
    const formErrors = useSelector((store: any) => store.formErrorReducer.errors);
    const [open, setOpen] = React.useState<boolean>(false);
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [address, setAddress] = React.useState<IAddress>(initialData);

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    const handleSubmit = async () => {
        dispatch(clearFormErrors());
        setProcessing(true);

        try {
            const updatedAddress = await createAddress(address);

            setOpen(false);
            onFinished(updatedAddress);
            setAddress(initialData);
        } catch (ex) {}

        setProcessing(false);
    };

    return (
        <React.Fragment>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="xs">
                <DialogTitle>Adresse hinzufügen</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2} style={{ marginTop: 5 }}>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Location"
                                value={address.location}
                                onChange={event => setAddress({ ...address, location: event.target.value })}
                                error={Boolean(formErrors.location)}
                                helperText={formErrors.location}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Straße"
                                value={address.address_street}
                                onChange={event => setAddress({ ...address, address_street: event.target.value })}
                                error={Boolean(formErrors.address_street)}
                                helperText={formErrors.address_street}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Hausnummer"
                                value={address.address_house_number}
                                onChange={event => setAddress({ ...address, address_house_number: event.target.value })}
                                error={Boolean(formErrors.address_house_number)}
                                helperText={formErrors.address_house_number}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="PLZ"
                                value={address.address_zip}
                                onChange={event => setAddress({ ...address, address_zip: event.target.value })}
                                error={Boolean(formErrors.address_zip)}
                                helperText={formErrors.address_zip}
                            />
                        </Grid>
                        <Grid item xs={12} md={8}>
                            <TextField
                                required
                                fullWidth
                                size="small"
                                label="Ort"
                                value={address.address_location}
                                onChange={event => setAddress({ ...address, address_location: event.target.value })}
                                error={Boolean(formErrors.address_location)}
                                helperText={formErrors.address_location}
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Abbrechen</Button>
                    <Button disabled={processing} variant="contained" onClick={handleSubmit}>Speichern</Button>
                </DialogActions>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    );
}

export default AddAddressDialog;
