import React from 'react';
import { Breadcrumbs, Button, Chip, Container, Grid, IconButton, LinearProgress, Link, TextField, Tooltip, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import ProjectDialog from '../../components/dialogs/ProjectDialog';
import Paper from '@mui/material/Paper';
import { IProject } from '../../models/project';
import DeleteButton from '../../components/DeleteButton';
import DataTable from '../../components/DataTable';
import { getProjects } from '../../service/project.service';
import { IUser } from '../../models/user';
import AddIcon from '@mui/icons-material/Add';
import { Edit } from '@mui/icons-material';
import Keycloak from 'keycloak-js';
import { Link as NavLink } from 'react-router-dom';
import SettingsIcon from '@mui/icons-material/Settings';
import ProjectCartCount from '../../components/ProjectCartCount';

interface ProjectsProps {
    user: IUser,
    name: string,
    keyCloak: Keycloak.KeycloakInstance,
}

function Projects({ name, user, keyCloak }: ProjectsProps) {
    const [projects, setProjects] = React.useState<IProject[]>();
    const [selected, setSelected] = React.useState<IProject>();
    const [query, setQuery] = React.useState<string>('');

    React.useEffect(() => fetchData(), [query]);

    const fetchData = () => {
        getProjects(query)
            .then(setProjects)
            .catch(console.error);
    };

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Typography color="text.primary">Projekte</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item>
                                <ProjectDialog onFinish={fetchData} initialUser={user} userName={name}>
                                    <Button startIcon={<AddIcon />} variant="contained">Neues Projekt erstellen</Button>
                                </ProjectDialog>
                            </Grid>
                            {keyCloak.hasRealmRole('verwaltung_projekte') && (
                                <Grid item>
                                    <NavLink to="/manage/projekte">
                                        <Button variant="contained" startIcon={<SettingsIcon />}>Projekte verwalten</Button>
                                    </NavLink>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Suchen ..."
                            variant="standard"
                            value={query}
                            onChange={event => setQuery(event.target.value)}
                        />
                    </Grid>
                    {selected && (
                        <ProjectDialog
                            onFinish={fetchData}
                            initialOpen={true}
                            initialData={selected}
                            onClose={() => setSelected(undefined)}
                        />
                    )}
                    <Grid item xs={12}>
                        {!projects ? (
                            <LinearProgress />
                        ) : (
                            <DataTable
                                onCellClick={(params) => {
                                    if (params.field === '_id') {
                                        return;
                                    }

                                    setSelected(params.row);
                                }}
                                rows={projects}
                                columns={[
                                    {
                                        field: 'id',
                                        headerName: 'ID',
                                        flex: 0.1,
                                    },
                                    {
                                        field: 'name',
                                        headerName: 'VA Name',
                                        flex: 0.4,
                                    },
                                    {
                                        field: 'start_date',
                                        headerName: 'Von',
                                        flex: 0.1,
                                        renderCell: ({ row }: { row: IProject}) => row.start_date?.format('DD.MM.YYYY'),
                                    },
                                    {
                                        field: 'end_date',
                                        headerName: 'Bis',
                                        flex: 0.1,
                                        renderCell: ({ row }: { row: IProject}) => row.end_date?.format('DD.MM.YYYY'),
                                    },
                                    {
                                        field: 'status',
                                        headerName: 'Status',
                                        flex: 0.2,
                                        renderCell: ({ row }: { row: IProject}) => <Chip label={row.status} />,
                                    },
                                    {
                                        field: '_id',
                                        headerName: 'Aktionen',
                                        flex: 0.15,
                                        align: 'right',
                                        headerAlign: 'right',
                                        renderCell: ({ row }: { row: IProject }) => {
                                            return (
                                                <div>
                                                    <Tooltip title="Warenkorb ansehen">
                                                        <ProjectCartCount projectId={row.id!} />
                                                    </Tooltip>
                                                    <ProjectDialog onFinish={fetchData} initialData={row}>
                                                        <IconButton>
                                                            <Edit />
                                                        </IconButton>
                                                    </ProjectDialog>
                                                    <DeleteButton
                                                        confirm
                                                        resource={`projects/${row.id}`}
                                                        onDelete={fetchData}
                                                    />
                                                </div>
                                            );
                                        },
                                    },
                                ]}
                            />
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default Projects;
