import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/Logout';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import SettingsIcon from '@mui/icons-material/Settings';
import { Grid, Theme, Tooltip } from '@mui/material';
import Keycloak from 'keycloak-js';
import { useHistory } from 'react-router-dom';
import ShoppingCart from './ShoppingCart';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import { useDispatch, useSelector } from 'react-redux';
import { uiConstants } from '../reducers/ui.reducer';
import { IUser } from '../models/user';
import { Category } from '@mui/icons-material';

const drawerWidth = 200;

const openedMixin = (theme: Theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(7)} + 1px)`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }: any) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    // @ts-ignore
    ({ theme, open }) => ({
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface MiniDrawerProps {
    children: any,
    keyCloak: Keycloak.KeycloakInstance,
    user: IUser,
}

export default function MiniDrawer({ children, keyCloak, user }: MiniDrawerProps) {
    const dispatch = useDispatch();
    const { darkMode } = useSelector((store: any) => store.uiReducer);
    const theme = useTheme();
    const history = useHistory();
    const [open, setOpen] = React.useState<boolean>(false);

    const handleToggleDrawer = (): void => setOpen(!open);

    const toggleDarkMode = (): void => {
        dispatch({ type: uiConstants.TOGGLE_DARK_MODE });
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed">
                <Toolbar>
                    <Grid container justifyContent="space-between" alignItems="center">
                        <Grid item>
                            <Grid container alignItems="center" spacing={2}>
                                <Grid item>
                                    <IconButton
                                        color="inherit"
                                        aria-label="open drawer"
                                        onClick={handleToggleDrawer}
                                        edge="start"
                                    >
                                        <MenuIcon />
                                    </IconButton>
                                </Grid>
                                <Grid item>
                                    <Typography variant="h6" noWrap component="div">
                                        NDR
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ShoppingCart user={user} keyCloak={keyCloak} />
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer variant="permanent" open={open}>
                <DrawerHeader>
                    <IconButton onClick={handleToggleDrawer}>
                        {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
                    </IconButton>
                </DrawerHeader>
                <Divider />
                <List>
                    <Tooltip title="Home">
                        <ListItem button onClick={() => history.push('/')}>
                            <ListItemIcon>
                                <HomeIcon />
                            </ListItemIcon>
                            <ListItemText primary="Dashboard" />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Artikel">
                        <ListItem button onClick={() => history.push('/artikel')}>
                            <ListItemIcon>
                                <ShoppingCartIcon />
                            </ListItemIcon>
                            <ListItemText primary="Artikel" />
                        </ListItem>
                    </Tooltip>
                    {!keyCloak.hasRealmRole('zdf') && (
                        <Tooltip title="Markenübersicht">
                            <ListItem button onClick={() => history.push('/markenübersicht')}>
                                <ListItemIcon>
                                    <Category />
                                </ListItemIcon>
                                <ListItemText primary="Markenübersicht" />
                            </ListItem>
                        </Tooltip>
                    )}
                    <Tooltip title="Projekte">
                        <ListItem button onClick={() => history.push('/projekte')}>
                            <ListItemIcon>
                                <PostAddIcon />
                            </ListItemIcon>
                            <ListItemText primary="Projekte" />
                        </ListItem>
                    </Tooltip>
                    {keyCloak.hasRealmRole('verwaltung') && (
                        <Tooltip title="Verwaltung">
                            <ListItem button onClick={() => history.push('/verwaltung')}>
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Verwaltung" />
                            </ListItem>
                        </Tooltip>
                    )}
                </List>
                <Divider />
                <List>
                    <Tooltip title="Darkmode">
                        <ListItem button onClick={toggleDarkMode}>
                            <ListItemIcon>
                                {darkMode ? <LightModeIcon /> : <DarkModeIcon />}
                            </ListItemIcon>
                            <ListItemText primary="Dark Mode" />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Konto">
                        <ListItem button onClick={() => history.push('/profil')}>
                            <ListItemIcon>
                                <AccountBoxIcon />
                            </ListItemIcon>
                            <ListItemText primary="Konto" />
                        </ListItem>
                    </Tooltip>
                    <Tooltip title="Abmelden">
                        <a href={keyCloak.createLogoutUrl()}>
                            <ListItem button>
                                <ListItemIcon>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary="Abmelden" />
                            </ListItem>
                        </a>
                    </Tooltip>
                </List>
            </Drawer>
            <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
                <DrawerHeader />
                {children}
            </Box>
        </Box>
    );
}
