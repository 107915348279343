import React from 'react';
import { Button, Chip, Grid, Paper, Typography } from '@mui/material';
import { IArticleFetched } from '../models/article';
import { ArrowForwardIos } from '@mui/icons-material';
import { FileFetched } from '../models/file';
import noImage from '../images/no_image.png';
import ArticleInfoDialog from './dialogs/ArticleInfoDialog';

function Article({ article } : { article: IArticleFetched }) {
    return (
        <React.Fragment>
            <Paper variant="outlined" style={{ padding: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6">{article.name}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={2}>
                                {article.files.length > 0 ? (
                                    <img
                                        loading="lazy"
                                        height="auto"
                                        width="100%"
                                        alt="Uploaded File"
                                        src={(article.files[0] as FileFetched).url}
                                    />
                                ) : (
                                    <img
                                        src={noImage}
                                        width="100%"
                                        height="auto"
                                        alt="Kein Bild"
                                    />
                                )}
                            </Grid>
                            <Grid item xs>
                                <Grid container spacing={0.5}>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Artikelnummer</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{article.id}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Programm</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{article.program.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Kategorie</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{article.category.name}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Maße</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">H: {article.plain_height}cm B: {article.plain_width}cm T: {article.plain_depth}cm</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Lagerbestand</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{article.amount_total} Stück</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Tagesbestand</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant="body2">{article.amount_today} Stück</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container>
                                            <Grid item xs>
                                                <Typography variant="body2">Tags</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Grid container spacing={1}>
                                                    {article.tags.length === 0 ? (
                                                        <Grid item xs={12}>
                                                            <Typography variant="body2">Keine Tags vorhanden</Typography>
                                                        </Grid>
                                                    ) : article.tags.map(tag => (
                                                        <Grid item key={tag.name}>
                                                            <Chip label={tag.name} size="small" />
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <Grid container alignItems="flex-end" style={{ height: '100%' }}>
                                    <Grid item>
                                        <ArticleInfoDialog article={article}>
                                            <Button startIcon={<ArrowForwardIos />}>Produktdetails</Button>
                                        </ArticleInfoDialog>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    );
}

export default Article;
