import React, { FormEvent, useState } from 'react';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import { Autocomplete, Dialog, DialogActions, DialogContent, DialogTitle, LinearProgress, TextField } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import Typography from '@mui/material/Typography';
import { formatBytes } from '../FileUploadButton';
import rest from '../../rest/rest';

interface Format {
    id: string,
    name: string,
}

const EXPORT_FORMATS: Format[] = [
    { id: 'xlsx', name: 'XLSX (Excel)' },
    { id: 'xls', name: 'XLS (Excel)' },
    { id: 'csv', name: 'CSV (Comma-separated values)' },
    { id: 'tsv', name: 'TSV (Tab-separated values)' },
    { id: 'ods', name: 'ODS (Office)' },
    { id: 'html', name: 'HTML' },
    { id: 'pdf', name: 'PDF' },
];

function ExportArticles() {
    const [open, setOpen] = useState(false);
    const [processing, setProcessing] = useState(false);
    const [response, setResponse] = useState<any>();
    const [format, setFormat] = useState<any>(EXPORT_FORMATS[0]);

    const handleOpen = () => {
        setOpen(true);
        setProcessing(false);
        setResponse(undefined);
    };

    const handleClose = () => setOpen(false);

    const handleExport = (event: FormEvent) => {
        event.preventDefault();
        setProcessing(true);

        rest.get(`article/articles/export?format=${format.id}`)
            .then(res => {
                setResponse(res.data);
                setProcessing(false);
            })
            .catch(err => {
                setProcessing(false);
                console.error(err);
            });
    };

    return (
        <div className="export-entries">
            <Dialog open={open} fullWidth maxWidth="xs" onClose={handleClose}>
                <DialogTitle>{response ? 'Export abgeschlossen' : 'Einträge exportieren'}</DialogTitle>
                <form onSubmit={handleExport}>
                    <DialogContent>
                        {processing && (
                            <LinearProgress />
                        )}

                        {!processing && !response && (
                            <Autocomplete
                                fullWidth
                                options={EXPORT_FORMATS}
                                onChange={(event, value) => setFormat(value)}
                                getOptionLabel={option => option.name}
                                renderInput={params => <TextField {...params} label="Format" variant="standard" />}
                            />
                        )}

                        {!processing && response && (
                            <Typography variant="body1">{response.count} Einträge exportiert</Typography>
                        )}
                    </DialogContent>
                    <DialogActions>
                        {response ? (
                            <React.Fragment>
                                <Button onClick={handleClose}>Abbrechen</Button>
                                <a onClick={handleClose} style={{ textDecoration: 'none' }} href={response.url} target="_blank" rel="noreferrer">
                                    <Button
                                        variant="contained"
                                        startIcon={<GetAppIcon />}
                                    >
                                        Download ({formatBytes(response.size)})
                                    </Button>
                                </a>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <Button onClick={handleClose}>Abbrechen</Button>
                                <Button
                                    variant="contained"
                                    startIcon={<SaveIcon />}
                                    disabled={processing}
                                    type="submit"
                                >
                                    Exportieren
                                </Button>
                            </React.Fragment>
                        )}
                    </DialogActions>
                </form>
            </Dialog>

            <Button onClick={handleOpen} startIcon={<SaveIcon />} variant="outlined">Einträge exportieren</Button>
        </div>
    );
}

export default ExportArticles;
