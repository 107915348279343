import rest from '../rest/rest';
import { IArticleProjectItem } from '../models/article';

export const addProjectCartItem = (projectId: number, articleId: number, amount: number): Promise<void> => {
    return rest.post(`projects/${projectId}/cart`, { amount, article_id: articleId });
};

export const getCartItems = async (projectId: number): Promise<IArticleProjectItem[]> => {
    const { data } = await rest.get(`projects/${projectId}/cart`);

    return data;
};

export const deleteCartItem = (projectItemId: number): Promise<void> => {
    return rest.delete(`projects/cartItems/${projectItemId}`);
};

export const updateCartItem = (article: IArticleProjectItem): Promise<void> => {
    return rest.post(`projects/cartItems/${article.id}`, article);
};

export const getCartCount = async (projectId: number): Promise<number> => {
    const { data } = await rest.get(`projects/${projectId}/cart/count`);

    return data;
}
