import React from 'react';
import { Grid, IconButton } from '@mui/material';
import { FileFetched } from '../models/file';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import noImage from '../images/no_image.png';

interface ImageGalleryProps {
    images: FileFetched[],
}

function ImageGallery({ images }: ImageGalleryProps) {
    const [index, setIndex] = React.useState<number>(0);

    const nextImage = () => setIndex(index + 1);

    const previousImage = () => setIndex(index - 1);

    const getImage = (): JSX.Element | null => {
        if (!images[index]) {
            return (
                <img
                    src={noImage}
                    width="100%"
                    height="auto"
                    alt="Kein Bild"
                />
            );
        }

        return (
            <img
                loading="lazy"
                height="auto"
                width="100%"
                alt="Uploaded File"
                src={images[index].url}
            />
        );
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Grid container justifyContent="center">
                    <Grid item>
                        {getImage()}
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}>
                    <Grid item>
                        <IconButton onClick={previousImage} disabled={index === 0}>
                            <ArrowBackIcon />
                        </IconButton>
                    </Grid>
                    <Grid item>
                        <IconButton onClick={nextImage} disabled={index >= images.length - 1}>
                            <ArrowForwardIcon />
                        </IconButton>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ImageGallery;
