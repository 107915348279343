import React from 'react';
import { MenuItem, Select } from '@mui/material';

const years = [
    2022,
    2023,
];

interface SelectYearProps {
    value: number,
    onChange: (value: number) => void,
}

function SelectYear({ value, onChange }: SelectYearProps) {
    return (
        <Select
            fullWidth
            required
            size="small"
            value={value}
            onChange={event => onChange(Number(event.target.value))}
        >
            <MenuItem value={0}>Jahr auswählen</MenuItem>
            {years.map(year => (
                <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
        </Select>
    );
}

export default SelectYear;