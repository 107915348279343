import React from 'react';
import { MenuItem, Select } from '@mui/material';
import { ProjectStatus } from '../../models/project';

const years = [
    2022,
    2023,
];

interface SelectStatusProps {
    value?: string,
    onChange: (value?: string) => void,
    error?: string,
    readOnly?: boolean,
}

function SelectStatus({ value, onChange, error, readOnly }: SelectStatusProps) {
    return (
        <Select
            fullWidth
            required
            size="small"
            value={value ?? 'empty'}
            onChange={event => {
                const value = event.target.value;

                if (value === 'empty') {
                    onChange(undefined);
                    return;
                }

                onChange(value);
            }}
            error={Boolean(error)}
            disabled={readOnly}
        >
            <MenuItem value="empty">Status auswählen</MenuItem>
            {Object.keys(ProjectStatus).map(statusKey => (
                <MenuItem key={statusKey} value={statusKey}>{ProjectStatus[statusKey]}</MenuItem>
            ))}
        </Select>
    );
}

export default SelectStatus;