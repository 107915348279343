import { IAddress } from '../models/address';
import rest from '../rest/rest';

export const getAddresses = async (): Promise<IAddress[]> => {
    const { data } = await rest.get('addresses');

    return data;
};

export const createAddress = async (address: IAddress): Promise<IAddress> => {
    const { data } = await rest.post('addresses', address);

    return data;
};
