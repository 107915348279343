import React from 'react';
import { Button, Chip, Dialog, DialogContent, Divider, Grid, IconButton, Skeleton, Typography } from '@mui/material';
import { Close, Print } from '@mui/icons-material';
import logo from '../../images/logo_urbanscope.png';
import Paper from '@mui/material/Paper';
import { IProject } from '../../models/project';
import { IArticleProjectItem } from '../../models/article';
import { getCartItems } from '../../service/cart.service';
import { DataGrid, GridColumns } from '@mui/x-data-grid';

interface PrintCartDialogProps {
    project: IProject,
    children: JSX.Element,
}

function PrintCartDialog({ project, children }: PrintCartDialogProps) {
    const [open, setOpen] = React.useState<boolean>(false);
    const [items, setItems] = React.useState<IArticleProjectItem[]>();

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getCartItems(project.id!)
            .then(setItems);
    };

    const getWeight = (article: IArticleProjectItem): string => {

        if (!article.article.plain_weight) {
            return '';
        }

        const weight: number = Number(article.amount) * article.article.plain_weight;

        if (weight > 999) {
            return `${weight / 1000}kg`;
        }

        return `${weight}g`;
    };

    const columns: GridColumns<IArticleProjectItem> = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.05,
            valueGetter: ({ row }) => row.article.id,
        },
        {
            field: 'zdf_id',
            headerName: `${project.user?.tenant?.name}-ID`,
            flex: 0.05,
            valueGetter: ({ row }) => row.article.zdf_id,
        },
        {
            field: 'amount',
            headerName: 'Anzahl',
            flex: 0.1,
        },
        {
            field: 'name',
            headerName: 'Name',
            flex: 0.3,
            valueGetter: ({ row }) => row.article.name,
        },
        {
            field: 'weight',
            headerName: 'Gewicht',
            flex: 0.1,
            valueGetter: ({ row }) => getWeight(row),
        },
        {
            field: 'storage',
            headerName: 'Lagerplatz',
            flex: 0.1,
            renderCell: ({ row }) => row.article.storage_areas.map(area => <Chip key={area.name} label={area.name} />),
        },
        {
            field: 'site_notes',
            headerName: 'Zusatzinfos',
            flex: 0.3,
            valueGetter: ({ row }) => row.site_notes,
        },
    ];

    const handleOpen = () => setOpen(true);

    const handleClose = () => setOpen(false);

    return (
        <React.Fragment>
            <Dialog open={open} fullScreen onClose={handleClose}>
                <DialogContent>
                    <Grid container spacing={2} style={{ zoom: '100%' }}>
                        <Grid item xs={12}>
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item>
                                    <Button startIcon={<Print />} variant="contained" onClick={() => window.print()}>
                                        Drucken
                                    </Button>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={handleClose}>
                                        <Close />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper style={{ padding: 20 }} variant="outlined" elevation={3}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <Grid container justifyContent="space-between" spacing={4}>
                                            <Grid item xs>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <Typography variant="h5">
                                                            {project?.name} ({project.id})
                                                        </Typography>
                                                        <Typography variant="subtitle2" color="text.secondary">
                                                            Erstellt am {project?.created_at?.format('DD.MM.YYYY HH:mm')}
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12} md>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2">
                                                                            <strong>{project.contact_person ? 'Ansprechpartner' : 'Besteller'}</strong>
                                                                        </Typography>
                                                                        <Divider />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1">
                                                                            {project.contact_person ? (
                                                                                `${project.contact_person.name}`
                                                                            ) : (
                                                                                `${project.user?.name}`
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1">
                                                                            {project.contact_person ? (
                                                                                `${project.contact_person.phone_number}`
                                                                            ) : (
                                                                                `${project.user?.phone_number}`
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                            <Grid item xs={12} md={8}>
                                                                <Grid container spacing={1}>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body2">
                                                                            <strong>Lieferadresse</strong>
                                                                        </Typography>
                                                                        <Divider />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <Typography variant="body1">
                                                                            {project.address ? (
                                                                                `${project.address.address_street} ${project.address.address_house_number}, ${project.address.address_zip} ${project.address.address_location}`
                                                                            ) : (
                                                                                `${project.user?.address_street} ${project.user?.address_house_number}, ${project.user?.address_zip} ${project.user?.address_location}`
                                                                            )}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item>
                                                <img
                                                    alt="Logo"
                                                    loading="lazy"
                                                    src={logo}
                                                    width={200}
                                                    height="auto"
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {!items ? (
                                            <Grid container spacing={0}>
                                                <Grid item xs={12}>
                                                    <Skeleton width="100%" height={80} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width="100%" height={80} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Skeleton width="100%" height={80} />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <React.Fragment>
                                                {items.length === 0 && (
                                                    <Typography variant="body1">
                                                        Der Warenkorb ist leer.
                                                    </Typography>
                                                )}

                                                <DataGrid
                                                    rows={items}
                                                    columns={columns}
                                                    pageSize={100}
                                                    autoHeight
                                                    disableSelectionOnClick
                                                />
                                            </React.Fragment>
                                        )}
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2"><strong>Lieferanweisung / Kommentar</strong></Typography>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {project.delivery_instructions ?? 'Keine Hinweise'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item xs={12} style={{ marginTop: '40%' }}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Typography variant="body2"><strong>Hinweise für Logistik</strong></Typography>
                                                <Divider />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Typography variant="body1">
                                                    {project.intern_notes ?? 'Keine Hinweise'}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        </Grid>
                    </Grid>
                </DialogContent>
            </Dialog>

            <span onClick={handleOpen}>
                {children}
            </span>
        </React.Fragment>
    )
}

export default PrintCartDialog;