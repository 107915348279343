import React from 'react';
import { Breadcrumbs, Button, Container, Divider, Grid, Link as LinkBread, Paper, Typography } from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { Link } from 'react-router-dom';
import Keycloak from 'keycloak-js';

function Management({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <LinkBread
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </LinkBread>
                                    <Typography color="text.primary">Verwaltung</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="h5" gutterBottom>Verwaltung</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {keyCloak.hasRealmRole('verwaltung_artikel') && (
                                <Grid item>
                                    <Link to="/manage/artikel">
                                        <Button variant="outlined" size="large">Artikel</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_projekte') && (
                                <Grid item>
                                    <Link to="/manage/projekte">
                                        <Button variant="outlined" size="large">Projekte</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_kategorien') && (
                                <Grid item>
                                    <Link to="/manage/kategorien">
                                        <Button variant="outlined" size="large">Kategorien</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_programme') && (
                                <Grid item>
                                    <Link to="/manage/programme">
                                        <Button variant="outlined" size="large">Programme</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_tags') && (
                                <Grid item>
                                    <Link to="/manage/tags">
                                        <Button variant="outlined" size="large">Tags</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_kunden') && (
                                <Grid item>
                                    <Link to="/manage/kunden">
                                        <Button variant="outlined" size="large">Kunden</Button>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 20 }}>
                        <Typography variant="h5" gutterBottom>Logistikverwaltung</Typography>
                        <Divider />
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={4}>
                            {keyCloak.hasRealmRole('verwaltung_lager') && (
                                <Grid item>
                                    <Link to="/manage/lager">
                                        <Button variant="outlined" size="large">Lager</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_lagerflächen') && (
                                <Grid item>
                                    <Link to="/manage/lagerflächen">
                                        <Button variant="outlined" size="large">Lagerflächen</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_lagerflächen_einheiten') && (
                                <Grid item>
                                    <Link to="/manage/lagerflächen-einheiten">
                                        <Button variant="outlined" size="large">Lagerflächen Einheiten</Button>
                                    </Link>
                                </Grid>
                            )}
                            {keyCloak.hasRealmRole('verwaltung_verpackungsarten') && (
                                <Grid item>
                                    <Link to="/manage/verpackungsarten">
                                        <Button variant="outlined" size="large">Verpackungsarten</Button>
                                    </Link>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    {keyCloak.hasRealmRole('verwaltung_nutzer') && (
                        <React.Fragment>
                            <Grid item xs={12} style={{ marginTop: 20 }}>
                                <Typography variant="h5" gutterBottom>Nutzerverwaltung</Typography>
                                <Divider />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container spacing={4}>
                                    <Grid item>
                                        <a href="https://id.urban-scope.de/auth/admin" rel="noreferrer" target="_blank">
                                            <Button variant="outlined" size="large">KeyCloak Konsole</Button>
                                        </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                    )}
                </Grid>
            </Paper>
        </Container>
    );
}

export default Management;
