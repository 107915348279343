import React from 'react';
import { Autocomplete, AutocompleteValue, Grid, IconButton, TextField } from '@mui/material';
import rest from '../../rest/rest';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorAble } from '../../models/components';
import { IStorageAreaUnit } from '../../models/storageArea';
import StorageAreaUnitDialog from '../dialogs/StorageAreaUnitDialog';

interface SelectStorageAreaUnitProps extends ErrorAble {
    onChange: (id?: number) => void,
    value?: number,
    readOnly?: boolean,
}

function SelectStorageAreaUnit({ onChange, value, readOnly, error }: SelectStorageAreaUnitProps) {
    const [selected, setSelected] = React.useState<IStorageAreaUnit>();
    const [programs, setPrograms] = React.useState<IStorageAreaUnit[]>([]);

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected?.id), [selected]);

    const fetchData = () => {
        rest.get('logistic/storageAreaUnits')
            .then(res => {
                setPrograms(res.data);

                if (value) {
                    setSelected(res.data.filter((cat: any) => cat.id === value)[0]);
                }
            })
            .catch(console.error);
    };

    function getSelected() {
        if (programs.length > 0 && selected) {
            return selected;
        }

        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={programs.length < 1}
                    options={programs}
                    disabled={readOnly}
                    getOptionLabel={unit => unit.name}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            required
                            label="Einheit"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, value: AutocompleteValue<any, any, any, any>) => {
                        if (!value) {
                            setSelected(undefined);
                            return;
                        }

                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <StorageAreaUnitDialog onFinished={fetchData}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </StorageAreaUnitDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectStorageAreaUnit;
