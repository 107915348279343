import React from 'react';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorAble } from '../../models/components';
import { IContactPerson } from '../../models/contact';
import { getContactPersons } from '../../service/contact.service';
import AddContactDialog from '../dialogs/AddContactDialog';

interface SelectContactProps extends ErrorAble {
    onChange: (id?: number) => void,
    value?: number,
    readOnly?: boolean,
}

function SelectContact({ onChange, value, readOnly, error }: SelectContactProps) {
    const [selected, setSelected] = React.useState<IContactPerson>();
    const [contacts, setContacts] = React.useState<IContactPerson[]>();

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected?.id), [selected]);

    const fetchData = (): void => {
        getContactPersons()
            .then((contacts) => {
                setContacts(contacts);

                if (value) {
                    setSelected(contacts.find(contact => contact.id === value));
                }
            })
            .catch(console.error);
    };

    const getSelected = (): IContactPerson | null => {
        if (contacts && contacts.length > 0 && selected) {
            return selected;
        }

        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={!contacts}
                    options={contacts ?? []}
                    disabled={readOnly}
                    getOptionLabel={(contact) => `${contact.name} (${contact.email})`}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Ansprechpartner"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, value: any) => {
                        if (!value) {
                            setSelected(undefined);
                            return;
                        }

                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <AddContactDialog onFinished={(contact) => {
                            setSelected(contact);
                            fetchData();
                        }}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </AddContactDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectContact;
