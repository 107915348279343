import React from 'react';
import Paper from '@mui/material/Paper';
import {
    Alert,
    Avatar,
    Breadcrumbs,
    Button,
    Container,
    Divider,
    Grid,
    Grow,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemText,
    Skeleton,
    TextField,
    Tooltip,
    Typography
} from '@mui/material';
import HomeIcon from '@mui/icons-material/Home';
import { useHistory, useParams } from 'react-router-dom';
import { getDimensions, IArticleProjectItem } from '../../models/article';
import { deleteCartItem, getCartItems, updateCartItem } from '../../service/cart.service';
import { ArrowBackIos, PostAdd, Print } from '@mui/icons-material';
import noImage from '../../images/no_image.png';
import { FileFetched } from '../../models/file';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import { IProject, ProjectStatus } from '../../models/project';
import { getProject, requestProject } from '../../service/project.service';
import ArticleInfoDialog from '../../components/dialogs/ArticleInfoDialog';
import { setPrompt } from '../../actions/prompt.actions';
import { useDispatch } from 'react-redux';
import PrintCartDialog from '../../components/dialogs/PrintCartDialog';
import Keycloak from 'keycloak-js';

interface ProjectCartProps {
    keyCloak: Keycloak.KeycloakInstance,
}

function ProjectCart({ keyCloak }: ProjectCartProps) {
    const params: any = useParams();
    const dispatch = useDispatch();
    const history = useHistory();
    const [items, setItems] = React.useState<IArticleProjectItem[]>();
    const [project, setProject] = React.useState<IProject>();
    const [processing, setProcessing] = React.useState<boolean>(false);
    const [changedItems, setChangedItems] = React.useState<boolean>(false);
    const [saved, setSaved] = React.useState<boolean>(false);

    const readOnly: boolean = keyCloak.hasRealmRole('warenkorb_read_only');

    React.useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        getProject(params.id)
            .then(setProject);
        getCartItems(params.id)
            .then(setItems);
    };

    const handleDeleteItem = async (id: number): Promise<void> => {
        setProcessing(true);

        try {
            await deleteCartItem(id);
        } catch (err) {
        }

        setProcessing(false);
        fetchData();
    };

    const handleUpdateItems = async (): Promise<void> => {

        if (!items) {
            return;
        }

        setProcessing(true);

        try {
            await Promise.all(
                items.map(changedItem => updateCartItem(changedItem))
            );

            setSaved(true);
            setChangedItems(false);

            setTimeout(() => {
                setSaved(false);
            }, 3000);
        } catch (err) {
            console.log('error: ', err);
        }

        setProcessing(false);
        fetchData();
    };

    const handleRequestProject = async (confirm: boolean): Promise<void> => {
        if (!confirm) {
            dispatch(
                setPrompt(
                    'Projekt wirklich anfragen?',
                    'Möchten Sie das Projekt jetzt wirklich anfragen? Der Warenkorb kann dann nur nach Rücksprache mit urban!scope angepasst werden.',
                    () => handleRequestProject(true)
                )
            );
            return;
        }

        setProcessing(true);

        try {
            await requestProject(project?.id!);
        } catch (err) {
            console.error('error while requesting project:', err);
        }

        setProcessing(false);
        fetchData();
    };

    const canSave: boolean = !items?.find(item => item.amount > item.article.amount_today);

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Dashboard
                                    </Link>
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/projekte"
                                    >
                                        <PostAdd sx={{ mr: 0.5 }} fontSize="inherit" />
                                        Projekte
                                    </Link>
                                    <Typography color="text.primary">Warenkorb</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1} alignItems="center">
                            <Grid item>
                                <Tooltip title="Zurück">
                                    <IconButton onClick={() => history.goBack()}>
                                        <ArrowBackIos style={{ marginLeft: 5 }} />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            <Grid item xs>
                                <Grid container justifyContent="space-between">
                                    <Grid item>
                                        <Typography variant="h5">
                                            {project?.name}
                                        </Typography>
                                    </Grid>
                                    {project && keyCloak.hasRealmRole('packscheine') && (
                                        <Grid item>
                                            <PrintCartDialog project={project}>
                                                <Button startIcon={<Print />} variant="contained">
                                                    Lieferschein / Packschein
                                                </Button>
                                            </PrintCartDialog>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider style={{ marginTop: 10 }} />
                    </Grid>
                    {project?.status && project.status !== ProjectStatus.Offen && (
                        <Grid item xs={12}>
                            <Alert severity="info">
                                Es können keine Artikel mehr geändert werden, weil das Projekt bereits angefragt ist.
                            </Alert>
                        </Grid>
                    )}
                    {saved && (
                        <Grid item xs={12}>
                            <Grow in>
                                <Alert severity="success" onClose={() => setSaved(false)}>
                                    Der Warenkorb wurde erfolgreich aktualisiert.
                                </Alert>
                            </Grow>
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        {!items ? (
                            <Grid container spacing={0}>
                                <Grid item xs={12}>
                                    <Skeleton width="100%" height={80} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton width="100%" height={80} />
                                </Grid>
                                <Grid item xs={12}>
                                    <Skeleton width="100%" height={80} />
                                </Grid>
                            </Grid>
                        ) : (
                            <React.Fragment>
                                {items.length === 0 && (
                                    <Typography variant="body1">
                                        Der Warenkorb ist leer.
                                    </Typography>
                                )}

                                <List>
                                    {items
                                        .map((item) => (
                                            <ListItem key={item.id} style={{ paddingLeft: 5, paddingRight: 0 }}>
                                                <Grid container spacing={2} justifyContent="space-between" alignItems="center">
                                                    <Grid item>
                                                        <IconButton
                                                            disabled={item.amount <= 1 || readOnly}
                                                            onClick={() => {
                                                                setItems([...items.filter(current => current.id !== item.id), {
                                                                    ...item,
                                                                    amount: Number(item.amount) - 1,
                                                                }]);
                                                                setChangedItems(true);
                                                            }}
                                                        >
                                                            <RemoveIcon fontSize="small" />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item>
                                                        <TextField
                                                            style={{ width: 60, marginBottom: 10 }}
                                                            label="Anzahl"
                                                            variant="standard"
                                                            size="small"
                                                            type="number"
                                                            value={item.amount}
                                                            onChange={event => {
                                                                setItems([...items.filter(current => current.id !== item.id), {
                                                                    ...item,
                                                                    amount: event.target.value,
                                                                }]);
                                                                setChangedItems(true);
                                                            }}
                                                            InputProps={{ inputProps: { min: 1, max: item.article.amount_today } }}
                                                            disabled={readOnly}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <IconButton
                                                            disabled={item.amount >= item.article.amount_today || readOnly}
                                                            onClick={() => {
                                                                setItems([...items.filter(current => current.id !== item.id), {
                                                                    ...item,
                                                                    amount: Number(item.amount) + 1,
                                                                }]);
                                                                setChangedItems(true);
                                                            }}
                                                        >
                                                            <AddIcon fontSize="small" />
                                                        </IconButton>
                                                    </Grid>
                                                    <Grid item style={{ cursor: 'pointer' }}>
                                                        <ArticleInfoDialog article={item.article} hideButtons>
                                                            <Avatar
                                                                alt={item.article.name}
                                                                src={item.article.files.length === 0 ? noImage : (item.article.files[0] as FileFetched).url}
                                                            />
                                                        </ArticleInfoDialog>
                                                    </Grid>
                                                    <Grid item xs={5} style={{ cursor: 'pointer' }}>
                                                        <ArticleInfoDialog article={item.article} hideButtons>
                                                            <ListItemText
                                                                style={{ marginLeft: 15 }}
                                                                primary={
                                                                    <Typography variant="body2">
                                                                        {item.article.name}
                                                                    </Typography>
                                                                }
                                                                secondary={`${getDimensions(item.article)}ID: ${item.article.id}`}
                                                            />
                                                        </ArticleInfoDialog>
                                                    </Grid>
                                                    <Grid item xs>
                                                        <TextField
                                                            style={{ marginBottom: 10 }}
                                                            fullWidth
                                                            label="Zusatzinfo"
                                                            variant="standard"
                                                            size="small"
                                                            value={item.site_notes}
                                                            onChange={event => {
                                                                setItems([...items.filter(current => current.id !== item.id), {
                                                                    ...item,
                                                                    site_notes: event.target.value,
                                                                }]);
                                                                setChangedItems(true);
                                                            }}
                                                            disabled={readOnly}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                        <Tooltip title="Artikel entfernen">
                                                            <IconButton disabled={processing || readOnly} onClick={() => handleDeleteItem(item.id)}>
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </Grid>
                                                    <Grow in={item.amount > item.article.amount_today} unmountOnExit>
                                                        <Grid item xs={12} style={{ marginBottom: 15 }}>
                                                            <Alert severity="warning">
                                                                Es sind nicht genügend Artikel von <strong>{item.article.name}</strong> im Lagerbestand (max: {item.article.amount_today}).
                                                            </Alert>
                                                        </Grid>
                                                    </Grow>
                                                </Grid>
                                            </ListItem>
                                        ))
                                    }
                                </List>
                            </React.Fragment>
                        )}
                        <Grid container justifyContent="space-between" style={{ marginTop: 20 }}>
                            <Grid item>
                                {project?.status === ProjectStatus.Offen && (
                                    <Button
                                        startIcon={<AddIcon />}
                                        onClick={() => history.push('/artikel')}
                                        disabled={readOnly}
                                    >
                                        Artikel hinzufügen
                                    </Button>
                                )}
                            </Grid>
                            <Grid item>
                                <Grid container spacing={2}>
                                    <Grid item>
                                        <Button
                                            variant="contained"
                                            disabled={!changedItems || processing || !canSave || readOnly}
                                            onClick={handleUpdateItems}
                                        >
                                            Speichern
                                        </Button>
                                    </Grid>
                                    {project?.status === ProjectStatus.Offen && (
                                        <Grid item>
                                            <Button
                                                variant="contained"
                                                disabled={!canSave || readOnly}
                                                onClick={() => handleRequestProject(false)}
                                            >
                                                Projekt anfragen
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ProjectCart;
