import React from 'react';
import { Autocomplete, Grid, IconButton, TextField } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ErrorAble } from '../../models/components';
import { getAddresses } from '../../service/address.service';
import { IAddress } from '../../models/address';
import AddAddressDialog from '../dialogs/AddAddressDialog';

interface SelectAddressProps extends ErrorAble {
    onChange: (id?: number) => void,
    value?: number,
    readOnly?: boolean,
}

function SelectAddress({ onChange, value, readOnly, error }: SelectAddressProps) {
    const [selected, setSelected] = React.useState<IAddress>();
    const [addresses, setAddresses] = React.useState<IAddress[]>();

    React.useEffect(() => fetchData(), []);

    React.useEffect(() => onChange(selected?.id), [selected]);

    const fetchData = (): void => {
        getAddresses()
            .then((addresses) => {
                setAddresses(addresses);

                if (value) {
                    setSelected(addresses.find(address => address.id === value));
                }
            })
            .catch(console.error);
    };

    const getSelected = (): IAddress | null => {
        if (addresses && addresses.length > 0 && selected) {
            return selected;
        }

        return null;
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs>
                <Autocomplete
                    size="small"
                    fullWidth
                    disablePortal
                    loading={!addresses}
                    options={addresses ?? []}
                    disabled={readOnly}
                    getOptionLabel={(address) => `${address.location} (${address.address_street} ${address.address_house_number}, ${address.address_zip} ${address.address_location})`}
                    value={getSelected()}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Lieferadresse"
                            error={Boolean(error)}
                            helperText={error}
                        />
                    )}
                    onChange={(event, value: any) => {
                        if (!value) {
                            setSelected(undefined);
                            return;
                        }

                        setSelected(value);
                    }}
                />
            </Grid>
            <Grid item>
                <Grid container justifyContent="center">
                    <Grid item>
                        <AddAddressDialog onFinished={(address) => {
                            setSelected(address);
                            fetchData();
                        }}>
                            <IconButton>
                                <AddCircleIcon />
                            </IconButton>
                        </AddAddressDialog>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default SelectAddress;
