import React from 'react';
import { Breadcrumbs, Chip, Container, Grid, IconButton, LinearProgress, Link, TextField, Tooltip, Typography } from '@mui/material';
import Paper from '@mui/material/Paper';
import HomeIcon from '@mui/icons-material/Home';
import DeleteButton from '../../components/DeleteButton';
import DataTable from '../../components/DataTable';
import { getProjects } from '../../service/project.service';
import ProjectDialog from '../../components/dialogs/ProjectDialog';
import { Edit } from '@mui/icons-material';
import { IProject, ProjectStatus } from '../../models/project';
import SettingsIcon from '@mui/icons-material/Settings';
import ProjectCartCount from '../../components/ProjectCartCount';
import SelectYear from '../../components/data/SelectYear';
import SelectStatus from '../../components/data/SelectStatus';
import { GridColumns } from '@mui/x-data-grid';
import SelectTenant from '../../components/data/SelectTenant';
import Keycloak from 'keycloak-js';

function ManageProjects({ keyCloak }: { keyCloak: Keycloak.KeycloakInstance }) {
    const [projects, setProjects] = React.useState<IProject[]>();
    const [selected, setSelected] = React.useState<IProject>();
    const [query, setQuery] = React.useState<string>('');
    const [year, setYear] = React.useState<number>(0);
    const [status, setStatus] = React.useState<string>();
    const [tenant, setTenant] = React.useState<number>();

    React.useEffect(() => fetchData(), [query, year, status, tenant]);

    const fetchData = (): void => {
        getProjects(query, 'all', year === 0 ? undefined : year, status, tenant)
            .then(setProjects)
            .catch(console.error);
    };

    const columns: GridColumns<IProject> = [
        {
            field: 'id',
            headerName: 'ID',
            flex: 0.1,
        },
        {
            field: 'tenant',
            headerName: 'Kunde',
            flex: 0.1,
            valueGetter: ({ row }) => row.user?.tenant?.name,
        },
        {
            field: 'creator',
            headerName: 'Ersteller',
            flex: 0.2,
            valueGetter: ({ row }) => row.user?.name,
        },
        {
            field: 'name',
            headerName: 'VA Name',
            flex: 0.3,
        },
        {
            field: 'start_date',
            headerName: 'Von',
            flex: 0.1,
            valueGetter: ({ row }) => row.start_date?.format('DD.MM.YYYY'),
        },
        {
            field: 'end_date',
            headerName: 'Bis',
            flex: 0.1,
            valueGetter: ({ row }) => row.end_date?.format('DD.MM.YYYY'),
        },
        {
            field: 'status',
            headerName: 'Status',
            flex: 0.2,
            renderCell: ({ row }) => <Chip label={ProjectStatus[row.status!]} />,
        },
        {
            field: '_id',
            headerName: 'Aktionen',
            flex: 0.15,
            align: 'right',
            headerAlign: 'right',
            renderCell: ({ row }) => {
                return (
                    <div>
                        <Tooltip title="Warenkorb ansehen">
                            <ProjectCartCount projectId={row.id!} />
                        </Tooltip>
                        <ProjectDialog onFinish={fetchData} initialData={row} intern readOnly={keyCloak.hasRealmRole('projekte_read_only')}>
                            <IconButton>
                                <Edit />
                            </IconButton>
                        </ProjectDialog>
                        {keyCloak.hasRealmRole('projekte_löschen') && (
                            <DeleteButton
                                confirm
                                resource={`projects/${row.id}`}
                                onDelete={fetchData}
                            />
                        )}
                    </div>
                );
            },
        },
    ];

    return (
        <Container maxWidth="xl">
            <Paper style={{ padding: 20 }} variant="elevation" elevation={3}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Breadcrumbs aria-label="breadcrumb">
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/"
                                    >
                                        <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit"/>
                                        Dashboard
                                    </Link>
                                    <Link
                                        underline="hover"
                                        sx={{ display: 'flex', alignItems: 'center' }}
                                        color="inherit"
                                        href="/verwaltung"
                                    >
                                        <SettingsIcon sx={{ mr: 0.5 }} fontSize="inherit"/>
                                        Verwaltung
                                    </Link>
                                    <Typography color="text.primary">Projekte</Typography>
                                </Breadcrumbs>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item style={{ width: 200 }}>
                                <SelectYear onChange={setYear} value={year} />
                            </Grid>
                            <Grid item style={{ width: 300 }}>
                                <SelectStatus value={status} onChange={setStatus} />
                            </Grid>
                            {!keyCloak.hasRealmRole('nest_one') && (
                                <Grid item style={{ width: 300 }}>
                                    <SelectTenant onChange={tenant => setTenant(tenant?.id)} hideCreateButton />
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            fullWidth
                            label="Suchen ..."
                            variant="standard"
                            value={query}
                            onChange={event => setQuery(event.target.value)}
                        />
                    </Grid>
                    {selected && (
                        <ProjectDialog
                            onFinish={fetchData}
                            initialOpen={true}
                            initialData={selected}
                            onClose={() => setSelected(undefined)}
                            intern
                        />
                    )}
                    <Grid item xs={12}>
                        {!projects ? (
                            <LinearProgress />
                        ) : (
                            <DataTable
                                onCellClick={(params) => {
                                    if (params.field === '_id') {
                                        return;
                                    }

                                    setSelected(params.row);
                                }}
                                rows={projects}
                                columns={columns}
                            />
                        )}
                    </Grid>
                </Grid>
            </Paper>
        </Container>
    );
}

export default ManageProjects;
