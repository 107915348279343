import { Alert, Button, Grid, IconButton, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import PublishIcon from '@mui/icons-material/Publish';
import { FileFetched } from '../models/file';
import rest from '../rest/rest';
import OpenFile from './OpenFile';

interface FileUploadButtonProps {
    onChange: (files: File[]) => void,
    files: FileFetched[],
}

export const formatBytes = (bytes: number) => {
    const sizes = ['b', 'kb', 'mb', 'gb', 'tb'];
    if (bytes === 0) return '0 b';
    // @ts-ignore
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1000)));
    // @ts-ignore
    return Math.round(bytes / Math.pow(1000, i), 2) + ' ' + sizes[i];
};

const FileUploadButton = ({ onChange, files }: FileUploadButtonProps) => {
    const formErrors = useSelector((store: any) => store.formErrorReducer).errors;
    const [deleting, setDeleting] = React.useState<string[]>([]);

    const handleFileDelete = async (file: FileFetched) => {
        if (file.id) {
            setDeleting([...deleting, file.name]);
            await rest.delete(`article/files/${file.id}`);
            setDeleting(deleting.filter((fileName: string) => fileName !== file.name));
        }

        onChange(files.filter(el => el.name !== file.name));
    };

    const isDeleting = (fileName: string): boolean => {
        return deleting.includes(fileName);
    };

    return (
        <Grid container spacing={2}>
            {formErrors.file && (
                <Grid item xs={12}>
                    <Alert severity="error">{formErrors.file}</Alert>
                </Grid>
            )}
            <Grid item xs={12}>
                {files.length < 1 ? (
                    <Alert severity="info" variant="filled" style={{ marginBottom: 40 }}>Es wurde noch keine Datei ausgewählt.</Alert>
                ) : (
                    <List dense>
                        {files.map((file: FileFetched) => (
                            <ListItem key={file.name} button>
                                <ListItemText primary={file.name} secondary={formatBytes(file.size)} />
                                <ListItemIcon>
                                    <Grid container spacing={2} justifyContent="end">
                                        {file.id && (
                                            <Grid item>
                                                <OpenFile id={file.id} />
                                            </Grid>
                                        )}
                                        <Grid item>
                                            <Tooltip title="Datei entfernen">
                                                <IconButton disabled={isDeleting(file.name)} onClick={() => handleFileDelete(file)}>
                                                    <DeleteIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </Grid>
                                    </Grid>
                                </ListItemIcon>
                            </ListItem>
                        ))}
                    </List>
                )}
            </Grid>
            <Grid item xs={12}>
                <Button
                    fullWidth
                    variant="contained"
                    component="label"
                    startIcon={<PublishIcon />}
                    size="large"
                >
                    Datei hochladen
                    <input
                        type="file"
                        multiple
                        style={{ display: 'none' }}
                        onChange={event => {
                            const selected = event.target.files ? Array.from(event.target.files) : [];

                            if (selected.length > 0) {
                                onChange([...files, ...selected]);
                            }
                        }}
                    />
                </Button>
            </Grid>
        </Grid>
    )
};

export default FileUploadButton;
